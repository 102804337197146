import PropTypes from 'prop-types';
import './TubeDialog.css';

function TubeDialog(props) {
    const { content, sx, isOpen, width } = props;
    return (
        <div className={`tube-dialog`} style={{
            width: width,
            display: isOpen ? "inline-block" : "none",
            ...sx
        }}>
            {content}
        </div>
    );
}

export default TubeDialog;

TubeDialog.propTypes = {
    content: PropTypes.any,
    sx: PropTypes.object,
    isOpen: PropTypes.bool,
    width: PropTypes.any,
};