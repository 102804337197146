import Form from "../form/Form";
import { edit } from '../../slices/channelSlice';
import { appUrl } from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

function Channel(props) {
    const { id, closeFunc } = props;
    const navigate = useNavigate();
    return (
        <Form 
            inputs={{
                title: {
                    label: "Channel Title",
                    field: "text",
                    width: 6,
                    merged: {
                        short_description: {
                            label: "Short Description",
                            field: "text",
                            width: 12
                        },
                        description: {
                            label: "Description",
                            field: "textarea",
                            width: 12
                        },
                        status: {
                            label: "Status",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Online", color: "green" },
                                { id: 2, label: "Offline", color: "red" },
                            ],
                            width: 12,
                            sx: {
                                borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                paddingBottom: "12px",
                                marginBottom: "24px"
                            }
                        },
                        categoriesNew: {
                            label: "Categories",
                            field: "multiselect",
                            data: "content-categories-search",
                            extraFuncType: "addNew",
                            extraFuncName: "categories",
                            extraFuncLabel: "New category"
                        },
                        tagsNew: {
                            label: "Tags",
                            field: "multiselect",
                            data: "content-tags-search",
                            extraFuncType: "addNew",
                            extraFuncName: "tags",
                            extraFuncLabel: "New tag",
                            lowercase: true
                        },
                        models: {
                            label: "Models",
                            field: "multiselect",
                            data: "models-search"
                        }
                    }
                },
                coverAndLogo: {
                    field: "cover",
                    width: 6,
                    logo: "current_logo",
                    cover: "current_cover",
                    merged: {
                        logo: {
                            label: "Logo",
                            field: "upload",
                            currentImage: "current_logo",
                            width: 3,
                            sx: {
                                float: "left",
                                width: "calc(50% - 10px)",
                                marginRight: "10px"
                            },
                            buttonLabel: "Upload Image",
                            noInput: true
                        },
                        cover: {
                            label: "Cover Image",
                            field: "upload",
                            currentImage: "current_cover",
                            width: 3,
                            buttonLabel: "Upload Image",
                            noInput: true
                        },
                        banner_wide: {
                            label: "Promo Banner Large",
                            field: "upload",
                            currentImage: "current_banner_wide",
                            sx: {
                                borderTop: "1px solid rgba(170, 187, 204, .5)",
                                paddingTop: "18px",
                                marginTop: "24px"
                            },
                            buttonLabel: "Upload Image",
                            noInput: true,
                            thumbnail: {
                                w: 180,
                                h: 180
                            }
                        },
                        banner_square: {
                            label: "Promo Banner Square",
                            field: "upload",
                            currentImage: "current_banner_square",
                            buttonLabel: "Upload Image",
                            noInput: true,
                            thumbnail: {
                                w: 180,
                                h: 180
                            },
                            sx: {
                                marginTop: "20px"
                            }
                        },
                        referral_link: {
                            label: "Referral link",
                            field: "text"
                        },
                        show_user: {
                            label: "Show user in video detail",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Show", color: "green" },
                                { id: 2, label: "Don't show", color: "red" },
                            ],
                            width: 12,
                            sx: {
                                borderTop: "1px solid rgba(170, 187, 204, .5)",
                                paddingTop: "12px",
                                marginTop: "24px"
                            }
                        }
                    }
                },
                empty: {
                    height: "40px",
                    field: "empty"
                }
            }}
            columns={2}
            stateName="channel"
            dispatchFunc={edit}
            title={id !== "new" ? "Edit User" : "Create User"}
            api={`${appUrl}/api/channel`}
            doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/channels/list/page/1`)} }}
            navbar
            detailId={id}
            required={["title", "short_description", "description", "categoriesNew"]}
            validation={[ "notNull", "notNull", "notNull", "notNull" ]}
        />
    );
}

export default Channel;

Channel.propTypes = {
    id: PropTypes.number
};