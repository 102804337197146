import DataTable from "../dataTable/DataTable";
import { edit } from '../../slices/contentTagsFilterSlice';
import { appUrl } from "../../utils/fetch";
import ContentTag from "./ContentTag";



function ContentTags() {
    return (
        <div>
            <DataTable 
                title="Content Tags"
                dataUrl={`${appUrl}/api/content-tags`}
                deleteUrl={`${appUrl}/api/content-tag`}
                editUrl={`/content-tag`}
                filterStateName="contentTagsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/content-tags`}
                url={"/content-tags"}
                editComponent={(id) => {
                    return (<ContentTag id={id}/>)
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    name: {
                        label: "Name",
                        field: "text",
                    },
                    color: {
                        label: "Color",
                        field: "color",
                    },
                }}
            />
        </div>
    );
}

export default ContentTags;