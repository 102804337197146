import Form from "../form/Form";
import { edit } from '../../slices/videoSlice';
import { appUrl } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { Stack } from "@mui/system";
import VideoPreview from "./VideoPreview";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import User from "./User";
import { useState } from "react";
import UserTabs from "./UserTabs";

function Video(props) {
    const { id, closeFunc, userDetail } = props;
    const isRequesting = false;
    const navigate = useNavigate();
    const videoData = useSelector((state) => state.video);
    const [ openUser, setOpenUser ] = useState(false);
    
    return (
        <>
            {!openUser ? (
                <Stack direction={"row"} spacing={3}>
                    {isRequesting ? (
                        <Box sx={{ width: '100%', paddingTop: "30px" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Form 
                                inputs={!id ? {
                                    name: {
                                        label: "Title",
                                        field: "text"
                                    },
                                    description: {
                                        label: "Description",
                                        field: "textarea"
                                    },
                                    date_scheduled: {
                                        label: "Date Scheduled",
                                        field: "date",
                                        border: true,
                                        time: true
                                    },
                                    file: {
                                        label: "Browse for video",
                                        field: "upload",
                                        border: true
                                    },
                                    status: {
                                        label: "Status",
                                        field: "buttonswitch",
                                        data: [
                                            { id: 1, label: "Online", color: "green" },
                                            { id: 2, label: "Offline", color: "red" },
                                        ],
                                        width: 6,
                                        border: true
                                    },
                                    type: {
                                        label: "Type",
                                        field: "buttonswitch",
                                        data: [
                                            { id: 1, label: "Public" },
                                            { id: 2, label: "Private" },
                                            { id: 3, label: "Premium" },
                                        ],
                                        width: 6,
                                        border: true
                                    },
                                    user_id: {
                                        label: "User",
                                        field: "select",
                                        data: "user_id",
                                        prepend: {
                                            0: "Admin"
                                        }
                                    },
                                    categoriesNew: {
                                        label: "Categories",
                                        field: "multiselect",
                                        data: "content-categories-search",
                                        extraFuncType: "addNew",
                                        extraFuncName: "categories",
                                        extraFuncLabel: "New category"
                                    },
                                    tagsNew: {
                                        label: "Tags",
                                        field: "multiselect",
                                        data: "content-tags-search",
                                        extraFuncType: "addNew",
                                        extraFuncName: "tags",
                                        extraFuncLabel: "New tag",
                                        lowercase: true
                                    },
                                    channels: {
                                        label: "Channels",
                                        field: "multiselect",
                                        data: "channels-search",
                                        disableMultiple: true
                                    },
                                    models: {
                                        label: "Models",
                                        field: "multiselect",
                                        data: "models-search"
                                    },
                                } : {
                                    name: {
                                        label: "Name",
                                        field: "text"
                                    },
                                    description: {
                                        label: "Description",
                                        field: "textarea",
                                        border: true 
                                    },
                                    date_scheduled: {
                                        label: "Date Scheduled",
                                        field: "date",
                                        border: true,
                                        time: true
                                    },
                                    status: {
                                        label: "Status",
                                        field: "buttonswitch",
                                        data: [
                                            { id: 1, label: "Online", color: "green" },
                                            { id: 2, label: "Offline", color: "red" },
                                        ],
                                        width: 6,
                                        border: true
                                    },
                                    type: {
                                        label: "Type",
                                        field: "buttonswitch",
                                        data: [
                                            { id: 1, label: "Public" },
                                            { id: 2, label: "Private" },
                                            { id: 3, label: "Premium" },
                                        ],
                                        width: 6,
                                        border: true
                                    },
                                    user_id: {
                                        label: "User",
                                        field: "select",
                                        data: "user_id",
                                        prepend: {
                                            0: "Admin"
                                        }
                                    },
                                    categoriesNew: {
                                        label: "Categories",
                                        field: "multiselect",
                                        data: "content-categories-search",
                                        extraFuncType: "addNew",
                                        extraFuncName: "categories",
                                        extraFuncLabel: "New category"
                                    },
                                    tagsNew: {
                                        label: "Tags",
                                        field: "multiselect",
                                        data: "content-tags-search",
                                        extraFuncType: "addNew",
                                        extraFuncName: "tags",
                                        extraFuncLabel: "New tag",
                                        lowercase: true
                                    },
                                    channels: {
                                        label: "Channels",
                                        field: "multiselect",
                                        data: "channels-search",
                                        disableMultiple: true
                                    },
                                    models: {
                                        label: "Models",
                                        field: "multiselect",
                                        data: "models-search"
                                    },
                                    empty: {
                                        height: "calc(28vw - 240px)",
                                        field: "empty"
                                    }
                                }}
                                columns={2}
                                stateName="video"
                                dispatchFunc={edit}
                                api={`${appUrl}/api/video`}
                                navbar
                                detailId={id}
                                meta
                                doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/videos/list/page/1`)} }}
                                required={id && id !== "new" ? [ "name", "categoriesNew" ] : [ "name", "categoriesNew", "file" ]}
                                validation={id && id !== "new" ? [ "notNull", "notNull" ] : [ "notNull", "notNull", "file" ]}
                            />
                            {id ? (
                                <VideoPreview
                                    dispatchFunc={edit}
                                    userDetail={() => setOpenUser(true)}
                                />
                            ) : null}
                        </>
                    )}
                </Stack>
            ) : (
                <div style={{width: "1200px"}}>
                    <UserTabs id={videoData.user_id} mainPage="videos" />
                </div>
            )}
        </>
    );
}

export default Video;

Video.propTypes = {
    id: PropTypes.number,
    closeFunc: PropTypes.func
};