import Form from "../form/Form";
import { edit } from '../../slices/contentTagSlice';
import { useParams } from "react-router-dom";
import { appUrl } from "../../utils/fetch";

function ContentTag() {
    const { id } = useParams();
    return (
        <Form 
            inputs={{
                name: {
                    label: "Name",
                    field: "text"
                },
                color: {
                    label: "Color",
                    field: "color"
                }
            }}
            columns={2}
            stateName="contentTag"
            dispatchFunc={edit}
            title={id && id !== "new" ? "Edit Content Tag" : "Create Content Tag"}
            api={`${appUrl}/api/content-tag`}
            navbar
            detailId={id}
        />
    );
}

export default ContentTag;