import { createSlice } from '@reduxjs/toolkit'

export const contentTagsFilterSlice = createSlice({
    name: 'contentTagsFilter',
    initialState: {
        id: "",
        name: ""
    },
    reducers: {
        edit: (state, payload) => {
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = contentTagsFilterSlice.actions

export default contentTagsFilterSlice.reducer