import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ModelsList from "./ModelsList";
import Model from "./Model";
import { edit } from '../../slices/modelSlice';
import { useDispatch } from "react-redux";

function Models() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Models"}
                tabs={
                    [
                        { 
                            title: "All models",
                            icon: <PeopleAltOutlinedIcon />,
                            href: "/models/list",
                            active: tab === "list",
                            content: (
                                <ModelsList />
                            )
                        },
                        { 
                            title: "New model",
                            icon: <PersonAddAltIcon />,
                            href: "/models/new",
                            active: tab === "new",
                            content: (
                                <Model />
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Models;