import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ChannelsList from "./ChannelsList";
import Channel from "./Channel";
import { edit } from '../../slices/channelSlice';
import { useDispatch } from "react-redux";

function Channels() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Channels"}
                tabs={
                    [
                        { 
                            title: "All channels",
                            icon: <PeopleAltOutlinedIcon />,
                            href: "/channels/list",
                            active: tab === "list",
                            content: (
                                <ChannelsList />
                            )
                        },
                        { 
                            title: "New channel",
                            icon: <PersonAddAltIcon />,
                            href: "/channels/new",
                            active: tab === "new",
                            content: (
                                <Channel />
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Channels;