import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: "",
}

export const pagesFilterSlice = createSlice({
    name: 'pagesFilter',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = pagesFilterSlice.actions

export default pagesFilterSlice.reducer