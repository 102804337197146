import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './TubeSlider.css';

export default function TubeSlider(props){
    const { label, stateName, valueName, dispatchFunc, disabled, range, unit } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();
    const [internalValue, setInternalValue] = useState([0, 100]);
    const timerRef = useRef();

    useEffect(() => {
        let tempValue = [ parseInt(internalValue[0] / 100 * range), parseInt(internalValue[1] / 100 * range) ];
        console.log(tempValue);
        console.log(value);
        if(tempValue[0] !== value[0] || tempValue[1] !== value[1]){
            console.log(range);
            handleChange(null, value[1] === 999 ? [value[0], 100] : tempValue);
        }
    }, [value])

    useEffect(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(setValue, 1000);
        return () => clearTimeout(timerRef.current);
    }, [internalValue]);

    const handleChange = (event, newValue) => {
        setInternalValue(newValue);
    };

    const setValue = () => {
        let tempValue = [ parseInt(internalValue[0] / 100 * range), parseInt(internalValue[1] / 100 * range) ];
        dispatch(dispatchFunc({ [valueName]: tempValue }));
    };
    
    return (
        <>
            <Slider
                aria-label={label}
                value={internalValue}
                className="tube-slider"
                disabled={disabled}
                onChange={handleChange}
                sx={{
                    width: "100%"
                }}
            />
            <div className='slider-range'>
                {parseInt(internalValue[0] / 100 * range)} - {parseInt(internalValue[1] / 100 * range)} {unit ? unit : "minutes"}
            </div>
        </>
    );
}

TubeSlider.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    range: PropTypes.number
};