import { createSlice } from '@reduxjs/toolkit'

export const contentCategoriesFilterSlice = createSlice({
    name: 'contentCategoriesFilter',
    initialState: {
        id: "",
        name: ""
    },
    reducers: {
        edit: (state, payload) => {
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = contentCategoriesFilterSlice.actions

export default contentCategoriesFilterSlice.reducer