import axios from 'axios';
import diacritics from 'diacritics';

const objectKeys = [
    "photosToUpload",
    "categories",
    "categoriesNew",
    "tagsNew",
    "tags",
    "languages",
    "country",
    "languages",
    "channels",
    "models"
]

//export const appUrl = "http://26.128.64.202:3001";
export const appUrl = "https://tubesbooster.com/cpanel/public";

export function post(url, body, done = () => {}, submit = () => {}, required = [], validation = []){
    let err = [];
    required.forEach((item, i) => {
        if(validation.length && validation[i] === "email" && !validateEmail(body[item])){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "short" && (!body[item].length || body[item].length < 5 || body[item].length > 10)){
            console.log(validation);
            err = [...err, item];
        }
        if(validation.length && validation[i] === "shortOrNull" && body[item].length && (body[item].length < 5 || body[item].length > 10)){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "notNull" && (!body[item] || !body[item].length)){
            err = [...err, item];
        }
        if(validation.length && validation[i] === "file" && !body[item].name){
            err = [...err, item];
            console.log(body[item].name);
        }
    });
    if(!err || !err.length){
        submit();
        let formData = new FormData();
        Object.keys(body).forEach(key => {
            if(objectKeys.includes(key)){
                Object.keys(body[key]).forEach(subKey => {
                    if(typeof body[key][subKey] === "object" && !body[key][subKey].size){
                        formData.append(`${key}[]`, body[key][subKey].id);
                    }
                    else {
                        formData.append(`${key}[]`, body[key][subKey]);   
                    }
                })
            }
            else {
                formData.append(key, body[key]);
            }
        });
        axios.post(url, formData, {
                headers: {
                    //'Authorization' : `Bearer ${cookies.get("auth")}`,
                    'Content-Type' : 'multipart/form-data'
                }
            })
            .then(function (response) {
                done(response);
            })
            .catch(function (error) {
            })
            .finally(function () {
            });
    }
    else {
        done({internalError: err});
    }
}

export function del(url, done = () => {}, submit = () => {}, body = {}){
    submit();
    axios.delete(url, body)
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
            done();    
        });
}

export function get(url, done = () => {}, submit = () => {}){
    submit();
    axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Show-All': true
            }
        })
        .then(function (response) {
            done(response);
        })
        .catch(function (error) {
            done(error);
        })
        .finally(function () {
        });
}

export function slugify(str = "") {
    str = diacritics.remove(str)
        .replace(/^\s+|\s+$/g, '')
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-');
    return str;
}

export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function getFormattedDate() {
    const months = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
    
    const now = new Date();
    const month = months[now.getMonth()];
    const day = now.getDate();
    const year = now.getFullYear();
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }

    const ordinalSuffix = getOrdinalSuffix(day);
    
    return `${month} ${day}${ordinalSuffix} ${year} ${hours}:${minutes}`;
}

export function timestampToDate(inputDateString) {
    var date = new Date(inputDateString);
    var formattedDate = (date.getMonth() + 1).toString().padStart(2, '0') + "/" + 
                        date.getDate().toString().padStart(2, '0') + "/" + 
                        date.getFullYear();
    return formattedDate;
}

export function capitalizeEveryWord(str) {
    if(str){
        let words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }
}