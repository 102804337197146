import DataTable from "../dataTable/DataTable";
import { edit } from '../../slices/usersGroupsFilterSlice';
import { appUrl } from "../../utils/fetch";
import UsersGroup from "./UsersGroup";



function UsersGroups() {
    return (
        <div>
            <DataTable 
                title="Users Groups"
                dataUrl={`${appUrl}/api/users-groups`}
                deleteUrl={`${appUrl}/api/users-group`}
                editUrl={`/users-group`}
                filterStateName="usersGroupsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/users-groups`}
                url={"/users-groups"}
                editComponent={(id) => {
                    return (<UsersGroup id={id}/>)
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    name: {
                        label: "Name",
                        field: "text",
                    },
                }}
            />
        </div>
    );
}

export default UsersGroups;