import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selected: [],
    selectedNames: []
};

export const grabbersSelectedSlice = createSlice({
    name: 'grabbersSelected',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = grabbersSelectedSlice.actions

export default grabbersSelectedSlice.reducer