//import PropTypes from 'prop-types';
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import ButtonSwitch from '../form/ButtonSwitch';
import { edit } from '../../slices/videosBulkEditSlice';
import MultiSelect from "../form/MultiSelect";
import DatePicker from "../form/DatePicker";
import { useEffect, useState } from "react";
import TubeSubmit from "../form/TubeSubmit";
import { post, appUrl } from '../../utils/fetch';
import PropTypes from 'prop-types';
import DialogComponent from "../dialog/Dialog";

function VideoBulkEdit(props) {
    const { handleClose, getData } = props;
    const selected = useSelector((state) => state.videosSelected);
    const videoData = useSelector((state) => state.video);
    const videoBulkData = useSelector((state) => state.videosBulkEdit);
    const [ categories, setCategories ] = useState({});
    const [ tags, setTags ] = useState({});
    const [ isLoadingUpdate, setIsLoadingUpdate ] = useState(false);
    const [ isOpenDialog, setIsOpenDialog ] = useState(false);
    const [ isOpenNoFiles, setIsOpenNoFiles ] = useState(false);

    const dispatch = useDispatch();
    
    useEffect(() => {
        let categoriesTemp = {};
        videoData.meta.categories.forEach(item => {
            categoriesTemp = {...categoriesTemp, [item.id]: item.name}
        });
        setCategories(categoriesTemp);
        let tagsTemp = {};
        videoData.meta.tags.forEach(item => {
            tagsTemp = {...tagsTemp, [item.id]: item.name}
        });
        setTags(tagsTemp);
    }, [videoData.meta.categories, videoData.meta.tags])

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div className='title-14'>Selected videos</div>
                    {selected.selectedNames.map((item, i) => (
                        <div class="bulk-edit-item">
                            {item}
                        </div>
                    ))}
                </Grid>
                <Grid item md={4}>
                    <div className='title-14'>Status</div>
                    <ButtonSwitch
                        stateName={"videosBulkEdit"}
                        valueName={"status"}
                        dispatchFunc={edit}
                        data={[
                            { id: 0, label: "Do not change" },
                            { id: 1, label: "Online", color: "green" },
                            { id: 2, label: "Offline", color: "red" }
                        ]}
                    />
                    <div className='title-14'>Type</div>
                    <ButtonSwitch
                        stateName={"videosBulkEdit"}
                        valueName={"type"}
                        dispatchFunc={edit}
                        data={[
                            { id: 0, label: "Do not Change" },
                            { id: 1, label: "Public" },
                            { id: 2, label: "Private" },
                            { id: 3, label: "Premium" }
                        ]}
                    />
                    <div className='bulk-edit-divider' />
                    <MultiSelect
                        stateName={"videosBulkEdit"}
                        valueName={"categories"}
                        dispatchFunc={edit}
                        label={"Categories"}
                        data={"content-categories-search"}
                    />
                    <MultiSelect
                        stateName={"videosBulkEdit"}
                        valueName={"tags"}
                        dispatchFunc={edit}
                        label={"Tags / Keywords"}
                        data={"content-tags-search"}
                    />
                </Grid>
                <Grid item md={4}>
                    <DatePicker
                        stateName={"videosBulkEdit"}
                        valueName={"date_scheduled"}
                        dispatchFunc={edit}
                        label={"Date Scheduled"}
                    />
                    <div className='bulk-edit-divider' />
                    <MultiSelect
                        stateName={"videosBulkEdit"}
                        valueName={"models"}
                        dispatchFunc={edit}
                        label={"Models"}
                        data={"models-search"}
                    />
                    <MultiSelect
                        stateName={"videosBulkEdit"}
                        valueName={"channels"}
                        dispatchFunc={edit}
                        label={"Channels"}
                        data={"channels-search"}
                    />
                </Grid>
                <Grid item md={12}>
                    <TubeSubmit
                        label="Update"
                        onClick={selected.selectedNames.length ? () => post(`${appUrl}/api/videos-bulk-edit`, {
                            ...videoBulkData,
                            selected: selected.selected
                        }, () => {
                            setIsLoadingUpdate(false);
                            getData();
                            handleClose();
                            setIsOpenDialog(true);
                            dispatch(edit("default"));
                        }, () => setIsLoadingUpdate(true)) : () => setIsOpenNoFiles(true)}
                        isLoading={isLoadingUpdate}
                    />
                    <TubeSubmit
                        variant="outlined"
                        sx={{
                            marginLeft: "10px"
                        }}
                        label="Cancel"
                        onClick={() => handleClose()}
                    />
                </Grid>
            </Grid>
            <DialogComponent
                content={"Changes have been made"}
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                buttons={[
                    {
                        variant: "contained",
                        label: "OK",
                        color: "primary",
                        onClick: () => setIsOpenDialog(false),
                    }
                ]}
            />
            <DialogComponent
                content={"You have not selected any files."}
                open={isOpenNoFiles}
                onClose={() => setIsOpenNoFiles(false)}
                buttons={[
                    {
                        variant: "contained",
                        label: "OK",
                        color: "primary",
                        onClick: () => setIsOpenNoFiles(false),
                    }
                ]}
            />
        </>
    );
}

export default VideoBulkEdit;

VideoBulkEdit.propTypes = {
    handleClose: PropTypes.func.isRequired,
    getData: PropTypes.func
};