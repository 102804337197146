import { Grid } from "@mui/material";
import { useSelector } from 'react-redux';

export default function ModelStats(props) {
    const modelData = useSelector((state) => state.model);
    return (
        <div className="video-card" style={{marginBottom: "20px"}}>
            <Grid container>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Videos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{modelData.videos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Albums / Photos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{modelData.albums} / {modelData.photos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Followers</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>0</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Profile views</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>0</div>
                </Grid>
            </Grid>
        </div>
    )
}