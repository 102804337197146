import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export default function DropDownMenu(props) {
    const { items, isOpen, sx } = props;

    return (
        <div style={{
            backgroundColor: "#fff",
            border: "1px solid #abc",
            borderRadius: 7,
            overflow: "hidden",
            width: "auto",
            position: "absolute",
            opacity: isOpen ? 100 : 0,
            visibility: isOpen ? "visible" : "hidden",
            transition: "visibility 0.2s, opacity 0.2s linear",
            ...sx
        }}>
            {items.map((item, i) => (
                <Link to={item.href} key={i} style={{
                    color: "#222",
                    padding: "10px 20px",
                    display: "block",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    margin: "0 4px 4px 0",
                    textDecoration: "none",
                    position: "relative",
                    lineHeight: "1.2rem",
                    fontSize: 14
                }}>
                    {item.name}
                </Link>
            ))}
        </div>
    )
}

DropDownMenu.propTypes = {
    items: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    sx: PropTypes.object
};