import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: "",
    categories: [],
    users: [],
    duration: [0,999],
}

export const videosFilterSlice = createSlice({
    name: 'videosFilter',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = videosFilterSlice.actions

export default videosFilterSlice.reducer