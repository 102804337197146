import DataTable from "../dataTable/DataTable";
import { edit } from '../../slices/videosFilterSlice';
import { edit as selected } from '../../slices/videosSelectedSlice';
import { appUrl } from "../../utils/fetch";
import Video from "./Video";
import VideoBulkEdit from "./VideoBulkEdit";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import VideosFilter from "./VideosFilter";
import "./VideoList.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function VideoList(props) {
    const { disableFilter, sx, userId, modelId, subpageUrl } = props;
    const video = useSelector((state) => state.video);

    return (
        <div style={sx}>
            {!disableFilter ? <VideosFilter /> : null}
            <DataTable 
                dataUrl={`${appUrl}/api/videos`}
                deleteUrl={`${appUrl}/api/video-delete`}
                editUrl={`/video`}
                filterStateName="videosFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/videos`}
                url={"/videos/list"}
                userId={userId}
                modelId={modelId}
                editComponentIsLoading={video.isLoading}
                editComponent={(id, closeFunc) => {
                    return (<Video id={id} closeFunc={closeFunc}/>)
                }}
                disableBulk={disableFilter}
                bulkEditComponent={(handleClose, getData) => { return (<VideoBulkEdit handleClose={() => handleClose()} getData={getData} />); }}
                selectedStateName="videosSelected"
                dispatchFunc={selected}
                subpageUrl={subpageUrl}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    title: { 
                        label: "Title",
                        field: "text"
                    },
                    user: {
                        label: "Uploader",
                        field: "text",
                        noSort: true
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    type: {
                        label: "Type",
                        field: "text"
                    },
                    duration: {
                        label: "Duration",
                        field: "text"
                    },
                    rating: {
                        label: "Rating",
                        field: "text"
                    },
                    embed: {
                        label: "Source",
                        field: "text"
                    },
                    views: {
                        label: "Views",
                        field: "text"
                    },
                    channels: {
                        label: "Channels",
                        field: "list"
                    },
                    models: {
                        label: "Models",
                        field: "list"
                    },
                    categories: {
                        label: "Categories",
                        field: "list"
                    },
                    published: {
                        label: "Published",
                        field: "text"
                    },
                    scheduled: {
                        label: "Scheduled",
                        field: "text"
                    }
                }}
                largeView={
                    [
                        [
                            {
                                id: "thumb",
                                type: "thumbnail"
                            }
                        ],
                        [
                            {
                                id: "user",
                                type: "avatar"
                            }
                        ],
                        [
                            {
                                id: "title",
                                type: "boldText"
                            },
                            {
                                id: "model",
                                type: "text"
                            },
                            {
                                id: "published",
                                type: "smallText"
                            },
                        ],
                        [
                            {
                                id: "views",
                                type: "text",
                                padding: 40,
                                icon: <VisibilityOutlinedIcon/>
                            },
                            {
                                id: "comments",
                                type: "text",
                                padding: 40,
                                icon: <ChatOutlinedIcon/>
                            },
                            {
                                id: "likes",
                                type: "text",
                                padding: 40,
                                icon: <FavoriteBorderOutlinedIcon/>
                            },
                        ],
                        [
                            {
                                id: "duration",
                                type: "text",
                                padding: 40,
                                icon: <AccessTimeOutlinedIcon/>
                            },
                            {
                                id: "status",
                                type: "text",
                                padding: 40,
                                icon: <VideocamOutlinedIcon/>
                            },
                            {
                                id: "type",
                                type: "text",
                                padding: 40,
                                icon: <LanguageOutlinedIcon/>
                            },
                        ]
                    ]
                }
            />
        </div>
    );
}

export default VideoList;