import React, { useState, useEffect } from "react";
import { Stack, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import SelectComponent from "../form/Select";

export default function DataTablePagination(props){
    const { page, url, totalPages, showPerPage, setShowPerPage } = props;
    const pagesToShow = 3;
    const [pageNumbers, setPageNumbers] = useState([]);

    const PageNumber = ({ number, active }) => (
        <Link to={`${url}/page/${number}`}>
            <IconButton className={`page-button ${active ? "page-button-active" : ""}`}>
                {number}
            </IconButton>
        </Link>
    );

    useEffect(() => {
        const newPageNumbers = [];
        const firstPage = 1;
        const lastPage = parseInt(totalPages, 10);
        const currentPage = parseInt(page, 10);
        if (currentPage > 1 + pagesToShow) {
            newPageNumbers.push(<PageNumber key="first" number={firstPage} />);
        }
        if (currentPage > pagesToShow + 2) {
            newPageNumbers.push(<span key="beforePrev">...</span>);
        }
        const prevPagesStart = Math.max(currentPage - pagesToShow, firstPage);
        for (let i = prevPagesStart; i < currentPage; i++) {
            newPageNumbers.push(<PageNumber key={i} number={i} />);
        }
        newPageNumbers.push(<PageNumber key={currentPage} number={currentPage} active />);
        const nextPagesEnd = Math.min(currentPage + pagesToShow, lastPage);
        for (let i = currentPage + 1; i <= nextPagesEnd; i++) {
            newPageNumbers.push(<PageNumber key={i} number={i} />);
        }
        if (currentPage < lastPage - pagesToShow - 1) {
            newPageNumbers.push(<span key="afterNext">...</span>);
        }
        if (currentPage < lastPage - pagesToShow) {
            newPageNumbers.push(<PageNumber key="last" number={lastPage} />);
        }
        setPageNumbers(newPageNumbers);
    }, [page, totalPages, url]);

    return (
        <Stack 
            direction={"row"}
            justifyContent="center"
            alignItems="center"
        >
            {pageNumbers.map((page, index) => (
                <React.Fragment key={index}>{page}</React.Fragment>
            ))}
            <SelectComponent
                noRedux
                noReduxValue={showPerPage}
                noReduxOnChange={setShowPerPage}
                data={{5:5, 10:10, 20:20, 50:50, 100:100}}
                label={"Show"}
                sx={{
                    marginTop: "5px",
                    width: "70px",
                    position: "absolute",
                    right: 30
                }}
            />
        </Stack>
    );
}

DataTablePagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    page: PropTypes.string
};