import DataTable from "../dataTable/DataTable";
import { edit as selected } from '../../slices/usersSelectedSlice';
import { edit } from '../../slices/usersFilterSlice';
import { appUrl } from "../../utils/fetch";
import User from "./User";
import UsersFilter from "./UsersFilter";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Tabs from "../navigation/Tabs";
import Videos from "./Videos";
import Albums from "./AlbumsList";
import VideoList from "./VideoList";
import { useNavigate, useParams } from "react-router-dom";
import { PeopleAltOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import UserTabs from "./UserTabs";



function UsersList() {
    const { subTab, page } = useParams();
    const navigate = useNavigate();
    return (
        <div>
            <UsersFilter />
            <DataTable 
                title="Users"
                dataUrl={`${appUrl}/api/users`}
                deleteUrl={`${appUrl}/api/user-delete`}
                editUrl={`/user`}
                filterStateName="usersFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/users`}
                url={"/users/list"}
                selectedStateName="usersSelected"
                dispatchFunc={selected}
                editOpenFunc={() => navigate(`/users/list/page/${page ? page : 1}/detail/about`)}
                protectedFirst
                editComponent={(id, closeFunc) => {
                    return (
                        <div style={{width: "calc(1200px)"}}>
                            <UserTabs id={id} closeFunc={closeFunc}/>
                        </div>
                    )
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    avatar: {
                        label: "Avatar",
                        field: "image",
                        alt: "display_name"
                    },
                    username: {
                        label: "Username",
                        field: "text",
                    },
                    email: {
                        label: "E-mail",
                        field: "text"
                    },
                    display_name: {
                        label: "Display Name",
                        field: "text"
                    },
                    last_login: {
                        label: "Last Login",
                        field: "date"
                    },
                    registration_date: {
                        label: "Registration Date",
                        field: "date"
                    },
                    content: {
                        label: "Content Videos/Pic",
                        field: "info",
                        noSort: true
                    },
                }}
                largeView={
                    [
                        [
                            {
                                id: "display_name",
                                type: "avatar",
                                size: 60,
                                online: true
                            }
                        ],
                        [
                            {
                                id: "display_name",
                                type: "boldText"
                            },
                            {
                                id: "email"
                            },
                            {
                                id: "registered",
                                type: "smallText"
                            }
                        ],
                        [
                            {
                                id: "content",
                                type: "text",
                                padding: 40,
                                icon: <VideocamOutlinedIcon/>
                            },
                            {
                                id: "photosTotal",
                                type: "text",
                                padding: 40,
                                icon: <CameraAltOutlinedIcon/>
                            },
                            {
                                id: "followersTotal",
                                type: "text",
                                padding: 40,
                                icon: <PersonAddAltOutlinedIcon/>
                            },
                        ],
                        [
                            {
                                id: "registeredStatus",
                                padding: 40,
                                type: "text"
                            },
                            {
                                id: "activityStatus",
                                padding: 40,
                                type: "text"
                            },
                            {
                                id: "type",
                                type: "text",
                                padding: 40,
                            },
                        ]
                    ]
                }
            />
        </div>
    );
}

export default UsersList;