import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useParams } from "react-router-dom";
import VideoList from "./VideoList";
import Video from "./Video";
import { edit } from '../../slices/videoSlice';
import { useDispatch } from "react-redux";
import GrabbersList from "./GrabbersList";

function Videos() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Videos"}
                tabs={
                    [
                        { 
                            title: "All videos",
                            icon: <VideocamOutlinedIcon />,
                            href: "/videos/list",
                            active: tab === "list",
                            content: (
                                <VideoList />
                            )
                        },
                        { 
                            title: "New video",
                            icon: <VideoCallOutlinedIcon />,
                            href: "/videos/new",
                            active: tab === "new",
                            content: (
                                <Video id={0}/>
                            )
                        },
                        { 
                            title: "Import",
                            icon: <FileDownloadOutlinedIcon />,
                            href: "/videos/import",
                            active: tab === "import",
                            content: (
                                <GrabbersList />
                            )
                        },
                        { 
                            title: "Export",
                            icon: <FileUploadOutlinedIcon />,
                            href: "/videos/export",
                            active: tab === "export",
                            content: (
                                <div>Content 4</div>
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Videos;