import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PagesList from "./PagesList";
import Page from "./Page";
import { edit } from '../../slices/pageSlice';
import { useDispatch } from "react-redux";

function Pages() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Pages"}
                tabs={
                    [
                        { 
                            title: "All pages",
                            icon: <PeopleAltOutlinedIcon />,
                            href: "/pages/list",
                            active: tab === "list",
                            content: (
                                <PagesList />
                            )
                        },
                        { 
                            title: "New page",
                            icon: <PersonAddAltIcon />,
                            href: "/pages/new",
                            active: tab === "new",
                            content: (
                                <Page />
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Pages;