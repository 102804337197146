import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import './TextArea.css';

export default function TextArea(props){
    const { label, stateName, valueName, dispatchFunc, disabled, sx, className } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
    }
    
    return (
        <TextField 
            id={valueName}
            label={label}
            variant="outlined"
            className={`text-area ${className}`}
            value={value ? value : ""}
            disabled={disabled}
            onChange={(e) => handleChange(e.target.value)}
            multiline
            rows={4}
            sx={sx}
        />
    );
}

TextArea.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};