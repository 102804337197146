import Form from "../form/Form";
import { edit } from '../../slices/pageSlice';
import { appUrl } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

function Page(props) {
    const { id, closeFunc } = props;
    const navigate = useNavigate();

    return (
        <Form 
            inputs={{
                title: {
                    label: "Page title",
                    field: "text"
                },
                content: {
                    label: "Page content",
                    field: "wysiwyg"
                },
                status: {
                    label: "Status",
                    field: "buttonswitch",
                    data: [
                        { id: 1, label: "Online", color: "green" },
                        { id: 2, label: "Offline", color: "red" },
                    ],
                    sx: {
                        marginBottom: "50px"
                    }
                }
            }}
            columns={2}
            stateName="page"
            dispatchFunc={edit}
            title={id !== "new" ? "Edit Page" : "Create Page"}
            api={`${appUrl}/api/content`}
            navbar
            doneFunc={() => {
                if(closeFunc){
                    closeFunc()
                }
                else {
                    navigate(`/pages/list/page/1`);
                    //window.location.reload();
                }
            }}
            detailId={id}
        />
    );
}

export default Page;

Page.propTypes = {
    id: PropTypes.number
};