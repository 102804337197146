import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

export default function SwitchComponent(props) {
    const { label, stateName, valueName, dispatchFunc, disabled } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
    }

    return (
        <FormControlLabel 
            control={<Switch />}
            label={label}
            checked={value}
            disabled={disabled}
            onChange={(e) => handleChange(e.target.checked)}
        />
    );
}

SwitchComponent.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};