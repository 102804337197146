import TextArea from "../form/TextArea";
import { edit as grabbersSource } from '../../slices/grabbersSourceSlice';
import { useSelector, useDispatch } from 'react-redux';
import TubeSubmit from "../form/TubeSubmit";
import { post, appUrl } from "../../utils/fetch"
import { TableCell, TableRow } from "@mui/material";
import "./GrabbersSourceDialog.css"

export default function GrabbersSourceDialog(props){
    const { id, onConfirm } = props;
    const grabbersSourceState = useSelector((state) => state.grabbersSource);

    const dispatch = useDispatch();

    return (
        <>
            {grabbersSourceState.isOpen && grabbersSourceState.id === id ? (
                <TableRow>
                    <TableCell colSpan={12} sx={{padding: "10px 0", borderTop: "1px solid #abc"}}>
                        <div className="grabbers-source">
                            <div className="grabbers-source-header">
                                Add source    
                            </div>
                            <TextArea
                                label="Source"
                                valueName="source"
                                dispatchFunc={grabbersSource}
                                stateName="grabbersSource"
                            />
                            <TubeSubmit
                                label={"Submit"}
                                icon="download"
                                sx={{
                                    position:"relative",
                                    marginTop: "10px"
                                }}
                                onClick={() => {onConfirm(); post(`${appUrl}/api/grabber-${grabbersSourceState.platform}`, grabbersSourceState, ()=>{}, () => dispatch(grabbersSource({isOpen: false})))}}
                            />
                        </div>
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    );
}