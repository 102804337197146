import PropTypes from 'prop-types';
import Header from './Header';
import { Link } from "react-router-dom";
import React from 'react';

export default function Tabs(props) {
    const { title, tabs, category } = props;

    return (
        <React.Fragment>
            {title ? <Header title={title} /> : null}
            <div style={{
                borderBottom: "1px solid #abc",
                alignItems: "center",
                display: "flex",
                marginTop: title ? 0 : -20,
                marginBottom: title ? 0 : 20
            }}>
                {tabs.map((tab, i) => (
                    <Link to={`${tab.href}`} onClick={tab.onClick} key={i} style={{
                        color: tab.active ? "#f84" : "rgba(68, 85, 102, .7)",
                        cursor: "pointer",
                        borderBottom: tab.active ? "2px solid #f84" : "2px solid rgba(0,0,0,0)",
                        alignItems: "center",
                        marginRight: 14,
                        padding: "25px 5px 10px 2px",
                        fontSize: 16,
                        fontWeight: 400,
                        transition: "all .2s",
                        display: "flex",
                        textDecoration: "none"
                    }}>
                        {tab.icon}
                        <span style={{
                            paddingLeft: 10,
                            paddingRight: 5
                        }}>
                            {tab.title}
                        </span>
                    </Link>
                ))}
            </div>
            <div>
                {tabs.map((tab, i) => (
                    <div key={i} style={{
                        display: tab.active ? "block" : "none"
                    }}>
                        {tab.content}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

Tabs.propTypes = {
    title: PropTypes.string,
    category: PropTypes.string,
    tabs: PropTypes.array.isRequired
};