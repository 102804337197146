import Form from "../form/Form";
import { edit } from '../../slices/albumSlice';
import { useParams } from "react-router-dom";
import { appUrl } from "../../utils/fetch";
import { Avatar, Grid, IconButton } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UserTabs from "./UserTabs";

function Album( props ) {
    const { id, closeFunc } = props;
    const albumData = useSelector((state) => state.album);
    const navigate = useNavigate();
    const [ openUser, setOpenUser ] = useState(false);
    
    return (
        
        <>
            {!openUser ? (
                <Form 
                    inputs={!id ? {
                        name: {
                            label: "Title",
                            field: "text",
                            width: 6, 
                            merged: {
                                description: {
                                    label: "Description",
                                    field: "textarea",
                                    width: 12
                                },
                                status: {
                                    label: "Status",
                                    field: "buttonswitch",
                                    data: [
                                        { id: 1, label: "Online", color: "green" },
                                        { id: 2, label: "Offline", color: "red" },
                                    ],
                                    width: 3,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        paddingTop: "12px",
                                        marginBottom: "24px",
                                        float: "left",
                                        marginRight: "20px"
                                    }
                                },
                                type: {
                                    label: "Type",
                                    field: "buttonswitch",
                                    data: [
                                        { id: 1, label: "Public" },
                                        { id: 2, label: "Private" },
                                    ],
                                    width: 3,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        marginBottom: "24px",
                                        paddingTop: "12px",
                                    }
                                },
                                user_id: {
                                    label: "User",
                                    field: "select",
                                    data: "user_id",
                                    prepend: {
                                        0: "Admin"
                                    }
                                },
                                categories: {
                                    label: "Categories",
                                    field: "multiselect",
                                    data: "content-categories-search",
                                    extraFuncType: "addNew",
                                    extraFuncName: "categories",
                                    extraFuncLabel: "New category"
                                },
                                tags: {
                                    label: "Tags",
                                    field: "multiselect",
                                    data: "content-tags-search",
                                    extraFuncType: "addNew",
                                    extraFuncName: "tags",
                                    extraFuncLabel: "New tag",
                                    lowercase: true
                                },
                                channels: {
                                    label: "Channels",
                                    field: "multiselect",
                                    data: "channels-search",
                                    disableMultiple: true
                                },
                                models: {
                                    label: "Models",
                                    field: "multiselect",
                                    data: "models-search"
                                }
                            }
                        },
                        empty: {
                            field: "empty",
                            height: "0",
                            width: 6,
                            merged: {
                                date_scheduled: {
                                    label: "Date Scheduled",
                                    field: "date",
                                    border: true,
                                    time: true,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        marginBottom: "24px",
                                    }
                                },
                                featured: {
                                    label: "Featured image",
                                    field: "upload",
                                    currentImage: "current_featured",
                                    sx: {
                                        marginTop: "24px"
                                    },
                                    buttonLabel: "Upload Image",
                                    noInput: true,
                                    thumbnail: {
                                        w: 180,
                                        h: 180
                                    }
                                },
                                photos: {
                                    label: "Upload Photos",
                                    field: "gallery",
                                    deleteUrl: "api/photo",
                                    sx: {
                                        marginTop: "24px"
                                    },
                                    thumbnail: {
                                        w: 180,
                                        h: 180
                                    },
                                    errorMsg: "You have to upload at least one image!"
                                }
                            }
                        }
                    } : {
                        name: {
                            label: "Title",
                            field: "text",
                            width: 6, 
                            merged: {
                                description: {
                                    label: "Description",
                                    field: "textarea",
                                    width: 12
                                },
                                status: {
                                    label: "Status",
                                    field: "buttonswitch",
                                    data: [
                                        { id: 1, label: "Online", color: "green" },
                                        { id: 2, label: "Offline", color: "red" },
                                    ],
                                    width: 3,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        paddingTop: "12px",
                                        marginBottom: "24px",
                                        float: "left",
                                        marginRight: "20px"
                                    }
                                },
                                type: {
                                    label: "Type",
                                    field: "buttonswitch",
                                    data: [
                                        { id: 1, label: "Public" },
                                        { id: 2, label: "Private" },
                                        { id: 3, label: "Premium" },
                                    ],
                                    width: 3,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        marginBottom: "24px",
                                        paddingTop: "12px",
                                    }
                                },
                                user_id: {
                                    label: "User",
                                    field: "select",
                                    data: "user_id",
                                    prepend: {
                                        0: "Admin"
                                    }
                                },
                                categories: {
                                    label: "Categories",
                                    field: "multiselect",
                                    data: "content-categories-search",
                                    extraFuncType: "addNew",
                                    extraFuncName: "categories",
                                    extraFuncLabel: "New category"
                                },
                                tags: {
                                    label: "Tags",
                                    field: "multiselect",
                                    data: "content-tags-search",
                                    extraFuncType: "addNew",
                                    extraFuncName: "tags",
                                    extraFuncLabel: "New tag",
                                    lowercase: true
                                },
                                channels: {
                                    label: "Channels",
                                    field: "multiselect",
                                    data: "channels-search",
                                    disableMultiple: true
                                },
                                models: {
                                    label: "Models",
                                    field: "multiselect",
                                    data: "models-search"
                                },
                                empty: {
                                    field: "empty",
                                    height: 40
                                }
                            }
                        },
                        empty: {
                            field: "empty",
                            height: "0",
                            width: 6,
                            merged: {
                                html: {
                                    field: "html",
                                    component: (
                                        <div className='video-card' style={{marginBottom: 20}}>
                                            <Grid
                                                container 
                                                spacing={0}
                                            >
                                                <Grid
                                                    item
                                                    md={1}
                                                >
                                                    <Avatar 
                                                        alt="Dummy User"
                                                        src={`${appUrl}/${albumData.userAvatar}`}
                                                    >
                                                        {albumData.userInitials}
                                                    </Avatar>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={10}
                                                >
                                                    <div className='video-card-user-name'>
                                                        {albumData.user}
                                                    </div>
                                                    <div className='video-card-user-registration'>
                                                        Registered: {albumData.userCreatedAt}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={1}
                                                >
                                                    <IconButton className='video-card-arrow' onClick={() => setOpenUser(true)}>
                                                        <ArrowForward fontSize={"inherit"} />
                                                    </IconButton>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    className={"video-card-line"}
                                                >
                                                    <div />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-name'>Photo count</div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-value'>{albumData.photos.length}</div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-name'>Album ID</div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-value'>{albumData.id}</div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    className={"video-card-line"}
                                                >
                                                    <div />
                                                </Grid>
                                                <Grid item />
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-name'>Imported from</div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                >
                                                    <div className='video-card-data-value'>{albumData.source ? albumData.source : "Direct upload"}</div>
                                                </Grid>
                                                {albumData.url ? (
                                                    <>
                                                        <Grid
                                                            item
                                                            md={6}
                                                        >
                                                            <div className='video-card-data-name'>Original URL</div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={6}
                                                        >
                                                            <div className='video-card-data-value'><a href={albumData.url} target="_blank" rel="noreferrer">{albumData.url}</a></div>
                                                        </Grid>
                                                    </>                        
                                                ) : ""}
                                            </Grid>
                                        </div>
                                    )
                                },
                                date_scheduled: {
                                    label: "Date Scheduled",
                                    field: "date",
                                    border: true,
                                    time: true,
                                    sx: {
                                        borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                        paddingBottom: "12px",
                                        marginBottom: "24px",
                                    }
                                },
                                featured: {
                                    label: "Featured image",
                                    field: "upload",
                                    currentImage: "current_featured",
                                    sx: {
                                        marginTop: "24px"
                                    },
                                    buttonLabel: "Upload Image",
                                    noInput: true,
                                    thumbnail: {
                                        w: 180,
                                        h: 180
                                    }
                                },
                                photos: {
                                    label: "Upload Photos",
                                    field: "gallery",
                                    deleteUrl: "api/photo",
                                    sx: {
                                        marginTop: "24px"
                                    },
                                    thumbnail: {
                                        w: 180,
                                        h: 180
                                    },
                                    errorMsg: "You have to upload at least one image!"
                                },
                                empty: {
                                    field: "empty",
                                    height: 50
                                }
                            }
                        }
                    }}
                    columns={2}
                    stateName="album"
                    dispatchFunc={edit}
                    title={id && id !== "new" ? "Edit Album" : "Create Album"}
                    api={`${appUrl}/api/album`}
                    doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/albums/list/page/1`)} }}
                    navbar
                    detailId={id}
                    meta
                    required={[ "name", "categories", "photos" ]}
                    validation={[ "notNull", "notNull", "notNull" ]}
                />
            
            ) : (
                <div style={{width: "1200px"}}>
                    <UserTabs id={albumData.user_id} mainPage="albums" />
                </div>
            )}
        </>
    );
}

export default Album;