import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import UsersList from "./UsersList";
import User from "./User";
import { edit } from '../../slices/userSlice';
import { useDispatch } from "react-redux";

function Users() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Users"}
                tabs={
                    [
                        { 
                            title: "All users",
                            icon: <PeopleAltOutlinedIcon />,
                            href: "/users/list",
                            active: tab === "list",
                            content: (
                                <UsersList />
                            )
                        },
                        { 
                            title: "New user",
                            icon: <PersonAddAltIcon />,
                            href: "/users/new",
                            active: tab === "new",
                            content: (
                                <User />
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Users;