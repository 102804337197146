import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    platform: "youtube",
    status: 2,
    importType: 1,
    type: 2,
    duplicated: 2,
    data: [],
    description: "",
    title_limit: "",
    description_limit: "",
    isLoading: 1
}

export const importSlice = createSlice({
    name: 'import',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = importSlice.actions

export default importSlice.reducer