import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    platform: "xhamster",
    url: ""
}

export const importPhotoSlice = createSlice({
    name: 'importPhoto',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = importPhotoSlice.actions

export default importPhotoSlice.reducer