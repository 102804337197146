import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: "",
    progress: 0,
    inProgress: false
};

export const progressSlice = createSlice({
    name: 'progress',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = progressSlice.actions

export default progressSlice.reducer