import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

function NavigationLink(props) {
    const { src, linkName, icon } = props;
    const location = useLocation();

    return (
        <NavLink 
            to={src} 
            style={{
                color: "#000",
                textDecoration: "none",
            }}
        >
            <MenuItem selected={location.pathname.split("/")[1] === src.split("/")[1]} sx={{
                '&.Mui-selected' : {
                    backgroundColor: "#004159",
                    color: "#fff !important"
                },
                '&:hover' : {
                    backgroundColor: "#fff",
                    color: "#004159 !important"
                },
                color: "rgba(68, 85, 102, 0.8)",
                borderRadius: 2
            }}>
                    {icon}
                    <ListItemText>
                        <span style={{
                            fontWeight: 300,
                            fontSize: 14
                        }}>
                            {linkName}        
                        </span>
                    </ListItemText>
            </MenuItem>
        </NavLink>
    );
}

NavigationLink.propTypes = {
    linkName: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    icon: PropTypes.object
};

export default NavigationLink;