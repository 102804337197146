import DataTable from "../dataTable/DataTable";
import { appUrl } from "../../utils/fetch";
import Page from "./Page";
import { edit } from '../../slices/pagesFilterSlice';
import { edit as selected } from '../../slices/usersSelectedSlice';
import { useNavigate, useParams } from "react-router-dom";

function PagesList() {
    const { page } = useParams();
    const navigate = useNavigate();
    return (
        <div>
            <DataTable 
                title="Pages"
                dataUrl={`${appUrl}/api/content`}
                deleteUrl={`${appUrl}/api/content-delete`}
                editUrl={`/page`}
                url={"/pages/list"}
                filterStateName="pagesFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/content`}
                disableSelect
                disableBulk
                editOpenFunc={() => navigate(`/pages/list/page/${page ? page : 1}`)}
                editComponent={(id, closeFunc) => {
                    return (
                        <Page id={id} closeFunc={() => closeFunc()}/>
                    )
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text",
                        noSort: true
                    },
                    title: {
                        label: "Title",
                        field: "text",
                        noSort: true
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        noSort: true,
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    }
                }}
            />
        </div>
    );
}

export default PagesList;