import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './TubeCheckbox.css';

function TubeCheckbox(props) {
    const { sx, checked, onChange } = props;
    return (
        <Button className={`tube-checkbox ${checked ? "tube-checkbox-active" : ""}`} onClick={onChange}>
            <CheckIcon sx={{
                fontSize: 16,
                ...sx
            }} />    
        </Button>
    );
}

export default TubeCheckbox;

TubeCheckbox.propTypes = {
    sx: PropTypes.object,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};