import * as React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "./DatePicker.css";

export default function DatePicker(props) {
    const { label, stateName, valueName, dispatchFunc, disabled, time, sx } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        console.log(value);
        dispatch(dispatchFunc({[valueName]: value}));
    }

    return (
        <>
            {!time ? (
                <div style={sx}>
                    <DesktopDatePicker
                        className='date-picker'
                        label={label}
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        ampm={false}
                    />
                </div>
            ) : (
                <div style={sx}>
                    <DateTimePicker
                        className='date-picker'
                        label={label}
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        ampm={false}
                    />
                </div>
            )}
        </>
    );
}

DatePicker.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    sx: PropTypes.object,
    disabled: PropTypes.bool
};