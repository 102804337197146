import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

const initialState = {
    status: 0,
    type: 0,
    categories: [],
    tags: [],
    models: [],
    channels: [],
    date_scheduled: null
}

export const videosBulkEditSlice = createSlice({
    name: 'videosBulkEdit',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = videosBulkEditSlice.actions

export default videosBulkEditSlice.reducer