import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import LastPageSharpIcon from '@mui/icons-material/LastPageSharp';
import AddIcon from '@mui/icons-material/Add';
import './TubeSubmit.css';
import { DeleteOutline } from '@mui/icons-material';

function TubeSubmit(props) {
    const { sx, isLoading, onClick, label, variant, className, redirectTo, icon } = props;

    function handleRedirect() {
        window.location.href = redirectTo;
    }

    function getIcon(icon){
        switch(icon){
            case "download": 
                return (
                    <LastPageSharpIcon className="tube-submit-button-icon r90" sx={{marginLeft: "-5px"}}/>
                );
            case "add": 
                return (
                    <AddIcon className="tube-submit-button-icon r90" sx={{marginLeft: "-5px"}}/>
                );
            case "delete": 
                return (
                    <DeleteOutline className="tube-submit-button-icon" sx={{marginLeft: "-5px"}}/>
                );
            default:
                return (<div></div>);
        }
    }

    return (
        <LoadingButton 
            variant="contained" 
            className={`confirm-button ${variant ? variant : ''} ${className}`}
            loading={isLoading}
            onClick={redirectTo ? handleRedirect : onClick}
            component="label"
            sx={sx}
        >
            {icon ? getIcon(icon) : null}
            {label}
        </LoadingButton>
    );
}

export default TubeSubmit;

TubeSubmit.propTypes = {
    sx: PropTypes.object,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.any,
    variant: PropTypes.string,
    className: PropTypes.string,
    redirectTo: PropTypes.string
};