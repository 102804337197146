import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

export default function CheckboxComponent(props) {
    const { label, stateName, valueName, dispatchFunc, disabled } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = () => {
        dispatch(dispatchFunc({[valueName]: !value}));
    }

    return (
        <FormControlLabel 
            control={<Checkbox />}
            checked={value}
            label={label}
            disabled={disabled}
            onChange={() => handleChange()}
            sx={{
                height: 1
            }}
        />
    );
}

CheckboxComponent.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}    