import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    name: "",
    categories: [],
    tags: [],
    channels: [],
    models: [],
    photos: [],
    photosToUpload: {},
    description: "",
    status: 1,
    type: 1,
    featured: "",
    current_featured: null,
    categories: [],
    meta: {
        user_id: []
    },
}

export const albumSlice = createSlice({
    name: 'album',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = {
                    ...initialState,
                    meta: state.meta
                };
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = albumSlice.actions

export default albumSlice.reducer