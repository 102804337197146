import TextInput from "../form/TextInput";
import { Grid } from "@mui/material";
import { edit } from '../../slices/usersFilterSlice';
import "./UsersFilter.css";
import MultiSelect from "../form/MultiSelect";
import ButtonSwitch from "../form/ButtonSwitch";

function UsersFilter() {
    return (
        <div className="users-filter" style={{
            borderRadius: "17px",
            padding: "17px 24px",
            backgroundColor: "#f2f4f8",
            marginTop: "20px"
        }}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <TextInput
                        stateName="usersFilter"
                        label="Search"
                        valueName="name"
                        dispatchFunc={edit}
                    />
                </Grid>
                <Grid item md={12}>
                    <ButtonSwitch
                        stateName="usersFilter"
                        label="Activity status"
                        valueName="status"
                        dispatchFunc={edit}
                        data={[
                            { id: 0, label: "All" },
                            { id: 1, label: "Active" },
                            { id: 2, label: "Inactive" },
                            { id: 4, label: "Banned" },
                            { id: 3, label: "Premium" }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default UsersFilter;