import DataTable from "../dataTable/DataTable";
import { edit } from '../../slices/albumsFilterSlice';
import { edit as selected } from '../../slices/albumsSelectedSlice';
import { appUrl } from "../../utils/fetch";
import Album from "./Album";
import AlbumsFilter from "./AlbumsFilter";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ChatOutlined, FavoriteBorderOutlined } from "@mui/icons-material";
import AlbumBulkEdit from "./AlbumBulkEdit";



function Albums(props) {
    const { disableFilter, sx, userId, modelId, subpageUrl } = props;
    return (
        <div style={sx}>
            {!disableFilter ? <AlbumsFilter /> : null}
            <DataTable 
                title="Albums"
                dataUrl={`${appUrl}/api/albums`}
                deleteUrl={`${appUrl}/api/album-delete`}
                editUrl={`/album`}
                userId={userId}
                modelId={modelId}
                filterStateName="albumsFilter"
                selectedStateName="albumsSelected"
                dispatchFunc={selected}
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/albums`}
                disableBulk={disableFilter}
                bulkEditComponent={(handleClose, getData) => { return (<AlbumBulkEdit handleClose={() => handleClose()} getData={getData} />); }}
                url={"/albums/list"}
                subpageUrl={subpageUrl}
                editComponent={(id, closeFunc) => {
                    return (<Album id={id} closeFunc={() => closeFunc()}/>)
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    name: {
                        label: "Name",
                        field: "text",
                    },
                    user: {
                        label: "Uploader",
                        field: "text",
                        noSort: true
                    },
                    model: {
                        label: "Model",
                        field: "text",
                        noSort: true
                    },
                    photos: {
                        label: "Photos",
                        field: "text"
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    type: {
                        label: "Type",
                        field: "text"
                    },
                    views: {
                        label: "Views",
                        field: "text"
                    },
                    comments: {
                        label: "Comments",
                        field: "text"
                    },
                    likes: {
                        label: "Likes",
                        field: "text"
                    },
                    created_at: {
                        label: "Published",
                        field: "text"
                    },
                }}
                largeView={
                    [
                        [
                            {
                                id: "thumb",
                                type: "photoThumbnail"
                            }
                        ],
                        [
                            {
                                id: "user",
                                type: "avatar"
                            }
                        ],
                        [
                            {
                                id: "name",
                                type: "boldText"
                            },
                            {
                                id: "model",
                                type: "text"
                            },
                            {
                                id: "created_at",
                                type: "smallText"
                            },
                        ],
                        [
                            {
                                id: "photos",
                                type: "text",
                                padding: 100,
                                icon: <PhotoSizeSelectActualOutlinedIcon />
                            },
                            {
                                id: "status",
                                type: "text",
                                padding: 100,
                                icon: <CameraAltOutlinedIcon />
                            },
                            {
                                id: "type",
                                type: "text",
                                padding: 100,
                                icon: <LanguageOutlinedIcon />
                            },
                        ],
                        [
                            {
                                id: "views",
                                type: "text",
                                padding: 40,
                                icon: <VisibilityOutlinedIcon />
                            },
                            {
                                id: "comments",
                                type: "text",
                                padding: 40,
                                icon: <ChatOutlined />
                            },
                            {
                                id: "likes",
                                type: "text",
                                padding: 40,
                                icon: <FavoriteBorderOutlined />
                            },
                        ]
                    ]
                }
            />
        </div>
    );
}

export default Albums;