import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function Title(props) {
    const { title, paddingLeft, fontSize } = props;

    return (
        <Typography variant="h1" component="div" sx={{    
            fontSize: fontSize ? fontSize : 28,
            paddingLeft: paddingLeft ? paddingLeft : 0,
            lineHeight: "1.1em",
            fontWeight: 500
        }}>
            {title}
        </Typography>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
    paddingLeft: PropTypes.number
};