import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { slugify as slugifyFunc } from '../../utils/fetch';
import './TextInput.css';

export default function TextInput(props){
    const { label, stateName, valueName, dispatchFunc, disabled, type, sx, noRedux, noReduxOnChange, noReduxValue, onClick, onBlur, className, slugify, onKeyDown, errorMsg, max } = props;
    const safeStateName = stateName || 'default';
    let value = useSelector((state) => state[safeStateName]?.[valueName]);
    let valueToSlug = useSelector((state) => {
        return slugify ? state[safeStateName]?.[slugify] : null;
    });
    const dispatch = useDispatch();
    const prevValueToSlugRef = useRef();

    useEffect(() => {
        if (prevValueToSlugRef.current !== valueToSlug) {
            if(valueToSlug){
                if(!value || slugifyFunc(prevValueToSlugRef.current) === value){
                    handleChange(slugifyFunc(valueToSlug));
                }
            }
            else {
                handleChange("");    
            }
            prevValueToSlugRef.current = valueToSlug;
        }
    }, [valueToSlug, slugifyFunc]);

    let handleChange;
    if (noRedux) {
        value = noReduxValue;
        handleChange = (value) => {
            noReduxOnChange(value);
        }
    }
    else {
        handleChange = (value) => {
            dispatch(dispatchFunc({[valueName]: value}));
        }
    }
    
    return (
        <div style={{
            display: 'inline-flex',
            flexDirection: 'column',
            width: '100%',
            ...sx
        }}>
            <TextField 
                id={valueName}
                label={label}
                variant="outlined"
                type={type ? type : "text"}
                className={`text-input ${className}`}
                value={value ? value : ""}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value)}
                onClick={onClick}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoComplete={"off"}
                inputProps={{ maxLength: max ? max : undefined }}
                sx={{
                    width: "100%",
                }}
            />
            {errorMsg && value && className === "internal-error" ? (<span className='form-error-msg'>{errorMsg}</span>) : null}
        </div>
    );
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    sx: PropTypes.object,
    onClick: PropTypes.func,
    onBlur: PropTypes.func
};