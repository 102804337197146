import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

const initialState = {
    username: "",
    password: "",
    display_name: "",
    stage_name: "",
    email: "",
    status: 1,
    country: [],
    city: "",
    avatar: {},
    birth_date: null,
    gender: 1,
    relationship: 1,
    orientation: 1,
    website: "",
    about_me: "",
    current_avatar: "",
    current_thumbnail: "",
    current_cover: "",
    languages: [],
    education: [],
    first_name: "",
    last_name: "",
    cover: {},
    age: "",
    ethnicity: 1,
    drinking: 1,
    smoking: 1,
    hair_length: 1,
    hair_color: 1,
    eye_color: 1,
    biography: ""
}

export const modelSlice = createSlice({
    name: 'model',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            console.log(payload);
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = modelSlice.actions

export default modelSlice.reducer