import React, { useEffect, useRef } from "react";
import "./CheckOutsideRefWrapper.css"

export default function CheckOutsideRefWrapper(props) {
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                props.func()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, props]);
    
    return <div className="check-outside-ref-wrapper" ref={wrapperRef}>{props.children}</div>;
}