import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: "",
    status: 0
}

export const usersFilterSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = usersFilterSlice.actions

export default usersFilterSlice.reducer