import Form from "../form/Form";
import { edit } from '../../slices/contentCategorySlice';
import { appUrl } from "../../utils/fetch";
import CategoryStats from "./CategoryStats";

function ContentCategory(props) {
    const { id, closeFunc } = props;
    return (
        <Form 
            inputs={{
                name: {
                    label: "Title",
                    field: "text",
                    width: 6,
                    merged: {
                        slug: {
                            label: "Slug",
                            field: "text",
                            slugify: "name"
                        },
                        description: {
                            label: "Description",
                            field: "textarea" 
                        },
                        parent_id: {
                            label: "Parent category",
                            field: "select",
                            data: "parent_id",
                            prepend: {
                                0: "Root category"
                            } 
                        },
                        border1: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0"}} />
                        },
                        status: {
                            label: "Status",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Online", color: "green" },
                                { id: 2, label: "Offline", color: "red" },
                            ]
                        },
                        border2: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0"}} />
                        },
                        thumbnail: {
                            label: "Category thumbnail",
                            field: "upload",
                            currentImage: "current_thumbnail",
                            buttonLabel: "Upload Image",
                            noInput: true,
                            thumbnail: {
                                w: 320,
                                h: 185
                            }
                        },
                        empty: {
                            field: "empty",
                            height: 40
                        }
                    }
                },
                tags: {
                    label: "Tags",
                    field: "multiselect",
                    width: 6,
                    data: "content-tags-search",
                    extraFuncType: "addNew",
                    extraFuncName: "tags",
                    extraFuncLabel: "New tag",
                    sx: {
                        borderTop: "none",
                        marginTop: -30
                    },
                    merged: {
                        border3: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0"}} />
                        },
                        seo_title: {
                            label: "SEO Title",
                            field: "text"
                        },
                        seo_description: {
                            label: "SEO Description",
                            field: "textarea"
                        },
                        seo_keywords: {
                            label: "SEO Keywords",
                            field: "text"
                        },
                        border4: {
                            field: "html",
                            component: <div style={{margin: "15px 0"}} />
                        },
                        stats: {
                            field: "html",
                            component: id ? <CategoryStats /> : null,
                        }
                    }
                },
            }}
            columns={2}
            stateName="contentCategory"
            dispatchFunc={edit}
            title={id && id !== "new" ? "Edit Content Category" : "Create Content Category"}
            api={`${appUrl}/api/content-category`}
            navbar
            doneFunc={closeFunc}
            detailId={id}
            required={[ "name" ]}
            validation={[ "notNull" ]}
        />
    );
}

export default ContentCategory;