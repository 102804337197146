import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    content: "",
    status: 1
}

export const pageSlice = createSlice({
    name: 'page',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = pageSlice.actions

export default pageSlice.reducer