import Form from "../form/Form";
import { edit } from '../../slices/userSlice';
import { appUrl } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import UserStats from "./UserStats";

function User(props) {
    const { id, closeFunc } = props;
    const navigate = useNavigate();

    return (
        <Form 
            inputs={{
                    display_name: {
                    label: "Display Name",
                    field: "text",
                    width: 6,
                    sx: {
                        width: "calc(50% - 5px)"
                    },
                    merged: {
                        username: {
                            label: "Username",
                            field: "text",
                            max: 10,
                            errorMsg: "Username must be 5-10 characters long",
                            sx: {
                                width: "calc(50% - 5px)",
                                marginLeft: "10px",
                                marginTop: "0px"
                            }
                        },
                        first_name: {
                            label: "First Name",
                            field: "text",
                            sx: {
                                width: "calc(50% - 5px)"
                            }
                        },
                        last_name: {
                            label: "Last Name",
                            field: "text",
                            sx: {
                                width: "calc(50% - 5px)",
                                marginLeft: "10px"
                            }
                        },
                        email: {
                            label: "E-mail",
                            errorMsg: "E-mail address must be in a valid format",
                            field: "text",
                            sx: {
                                width: "calc(50% - 5px)"
                            }
                        },
                        password: {
                            label: "Password",
                            field: "password",
                            errorMsg: "Password must be 5-10 characters long",
                            max: 10,
                            sx: {
                                width: "calc(50% - 5px)",
                                marginLeft: "10px"
                            }
                        },
                        about_me: {
                            label: "About Me",
                            field: "textarea"
                        },
                        country: {
                            label: "Country",
                            field: "multiselect",
                            data: "countries-search",
                            disableMultiple: true
                        },
                        city: {
                            label: "City",
                            field: "text"
                        },
                        status: {
                            label: "Status",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Active" },
                                { id: 2, label: "Inactive" },
                                { id: 4, label: "Banned" },
                                { id: 3, label: "Premium" }
                            ],
                            sx: {
                                borderTop: "1px solid rgba(170, 187, 204, .5)",
                                paddingTop: "20px",
                                marginTop: "10px",
                                paddingBottom: "10px",
                                borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                marginBottom: "20px",
                            }
                        },
                        coverAndLogo: {
                            field: "cover",
                            width: 6,
                            logo: "current_avatar",
                            cover: "current_cover",
                        },
                        avatar: {
                            label: "Avatar",
                            field: "upload",
                            currentImage: "current_avatar",
                            sx: {
                                float: "left",
                                width: "calc(50% - 5px)",
                                marginRight: "10px"
                            },
                            buttonLabel: "Upload Avatar",
                            noInput: true
                        },
                        cover: {
                            label: "Cover Image",
                            field: "upload",
                            currentImage: "current_cover",
                            width: 3,
                            buttonLabel: "Upload Cover",
                            noInput: true
                        },
                        empty: {
                            field: "empty",
                            height: 50
                        }
                    }
                },
                stats: {
                    field: "html",
                    component: id ? <UserStats /> : null,
                    width: 6,
                    merged: {
                        birth_date: {
                            label: "Date of birth",
                            field: "date",
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginTop: "0px",
                                float: "left"
                            },
                        },
                        orientation: {
                            label: "Orientation",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Straight",
                                2: "Gay",
                                3: "Lesbian",
                                4: "Not Sure",
                                5: "Bisexual"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px",
                                marginTop: "0px"
                            }
                        },
                        relationship: {
                            label: "Relationship",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Single",
                                2: "Married",
                                3: "Open",
                                4: "Divorced",
                                5: "Widowed"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px",
                                marginTop: "0px"
                            }
                        },
                        border1: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0"}} />
                        },
                        languages: {
                            label: "Languages",
                            field: "multiselect",
                            data: "languages-search",
                            loadEmpty: true
                        },
                        education: {
                            label: "Education",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "High school" ,
                                2: "High school graduate",
                                3: "College",
                                4: "Current college student",
                                5: "Associate degree (2 years college)",
                                6: "BA/BS (4 years college)",
                                7: "Grad school",
                                8: "Current grad school student",
                                9: "PhD/MD/Post doctorate",
                                10: "JD"
                            }
                        },
                        border2: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0", float: "left", width: "100%"}} />
                        },
                        ethnicity: {
                            label: "Ethnicity",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Asian",
                                2: "Black",
                                3: "Indian",
                                4: "Latino",
                                5: "Middle Eastern",
                                6: "Mixed",
                                7: "White",
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)"
                            }
                        },
                        drinking: {
                            label: "Drinking",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Never",
                                2: "Occasionally",
                                3: "Several times a week",
                                4: "Most days"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        smoking: {
                            label: "Smoking",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Never",
                                2: "Occasionally",
                                3: "Regularly"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        hair_length: {
                            label: "Hair length",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Short",
                                2: "Medium",
                                3: "Long",
                                4: "Bald"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)"
                            }
                        },
                        hair_color: {
                            label: "Hair color",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Black",
                                2: "Brown",
                                3: "Blonde",
                                4: "Red",
                                5: "Gray"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        eye_color: {
                            label: "Eye color",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Brown",
                                2: "Blue",
                                3: "Green",
                                4: "Gray",
                                5: "Black"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        }
                    }
                },
                // password: {
                //     label: "Password (leave blank if you don't want to change it)",
                //     field: "password",
                //     width: 6
                // },
                // display_name: {
                //     label: "Display Name",
                //     field: "text",
                //     width: 6
                // },
                // birth_date: {
                //     label: "Birth Date",
                //     field: "date",
                //     width: 6
                // },
                // gender: {
                //     label: "Gender",
                //     field: "select",
                //     data: {
                //         1: "Male",
                //         2: "Female",
                //         3: "Couple",
                //         4: "Transexual"
                //     },
                //     width: 6
                // },
            }}
            columns={2}
            stateName="user"
            dispatchFunc={edit}
            title={id !== "new" ? "Edit User" : "Create User"}
            api={`${appUrl}/api/user`}
            navbar
            doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/users/list/page/1`)} }}
            detailId={id}
            required={id != "new" && id > 0 ? ["username", "display_name", "email", "password"] : ["username", "display_name", "email", "password"]}
            validation={id != "new" && id > 0 ? [ "short", "notNull", "email", "shortOrNull" ] : [ "short", "notNull", "email", "short" ]}
        />
    );
}

export default User;

User.propTypes = {
    id: PropTypes.number
};