import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

const initialState = {
    id: 0,
    name: "",
    description: "",
    file: {},
    categories: [],
    categoriesNew: [],
    tags: [],
    tagsNew: [],
    channels: [],
    models: [],
    status: 1,
    type: 2,
    length: "",
    views: 0,
    fps: 0,
    date_scheduled: moment(new Date()),
    currentTime: 0,
    meta: {
        categories: [],
        tags: [],
        user_id: []
    },
    source: "",
    isLoading: 2,
    user_id: 0
}

export const videoSlice = createSlice({
    name: 'video',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = {
                    ...initialState,
                    meta: state.meta
                };
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = videoSlice.actions

export default videoSlice.reducer