import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

const initialState = {
    username: "",
    password: "",
    display_name: "",
    email: "",
    status: 1,
    country: [],
    city: "",
    avatar: {},
    birth_date: moment(new Date()),
    gender: 0,
    relationship: 0,
    orientation: 0,
    website: "",
    about_me: "",
    current_avatar: "",
    languages: [],
    education: [],
    first_name: "",
    last_name: "",
    cover: {},
    current_cover: "",
    age: "",
    ethnicity: 0,
    drinking: 0,
    smoking: 0,
    hair_length: 0,
    hair_color: 0,
    eye_color: 0
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = userSlice.actions

export default userSlice.reducer