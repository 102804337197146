import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    banner_wide: {},
    banner_square: {},
    current_banner_wide: "",
    current_banner_square: "",
    categories: [],
    categoriesNew: [],
    tags: [],
    tagsNew: [],
    models: [],
    logo: {},
    current_logo: "",
    cover: {},
    current_cover: "",
    title: "",
    short_description: "",
    description: "",
    referral_link: "",
    status: 1,
    show_user: 1,
    meta: {
        categories: [],
        tags: [],
    }
}

export const channelSlice = createSlice({
    name: 'channel',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = channelSlice.actions

export default channelSlice.reducer