import DataTable from "../dataTable/DataTable";
import { edit as selected } from '../../slices/channelsSelectedSlice';
import { edit } from '../../slices/channelsFilterSlice';
import { appUrl } from "../../utils/fetch";
import Channel from "./Channel";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import ChannelsFilter from "./ChannelsFilter";



function ChannelsList() {
    return (
        <div>
            <ChannelsFilter />
            <DataTable 
                title="Channels"
                dataUrl={`${appUrl}/api/channels`}
                deleteUrl={`${appUrl}/api/channel-delete`}
                editUrl={`/channel`}
                filterStateName="channelsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/channels`}
                url={"/channels/list"}
                selectedStateName="channelsSelected"
                dispatchFunc={selected}
                editComponent={(id, closeFunc) => {
                    return (<Channel id={id} closeFunc={closeFunc}/>)
                }}
                header={{
                    logo: {
                        label: "Logo",
                        field: "image",
                        alt: "display_name"
                    },
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    title: {
                        label: "Title",
                        field: "text",
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    short_description: {
                        label: "Short Description",
                        field: "text"
                    }
                }}
                largeView={
                    [
                        [
                            {
                                id: "title",
                                type: "avatar",
                                avatarName: "logo"
                            }
                        ],
                        [
                            {
                                id: "title",
                                type: "boldText"
                            },
                            {
                                id: "created",
                                type: "smallText"
                            },
                        ],
                        [
                            {
                                id: "likes",
                                type: "text",
                                padding: 40,
                                icon: <FavoriteBorderOutlinedIcon/>
                            },
                            {
                                id: "followers",
                                type: "text",
                                padding: 40,
                                icon: <PersonAddAltOutlinedIcon/>
                            },
                        ],
                        [
                            {
                                id: "views",
                                type: "text",
                                padding: 40,
                                icon: <VisibilityOutlinedIcon/>
                            },
                            {
                                id: "status",
                                type: "text",
                                padding: 40,
                                icon: <LanguageOutlinedIcon/>
                            },
                        ]
                    ]
                }
            />
        </div>
    );
}

export default ChannelsList;