import { Grid } from "@mui/material";
import { useSelector } from 'react-redux';

export default function CategoryStats(props) {
    const categoryData = useSelector((state) => state.contentCategory);
    return (
        <div className="video-card" style={{marginBottom: "20px"}}>
            <Grid container>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Videos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{categoryData.videos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Albums</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{categoryData.albums}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Created</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{categoryData.created_at}</div>
                </Grid>
            </Grid>
        </div>
    )
}