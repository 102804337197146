import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Wysiwyg.css';

export default function Wysiwyg(props){
    const { label, stateName, valueName, dispatchFunc, disabled, sx, className } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
    }
    
    return (
        <div
            className={`wysiwyg ${className}`}
            sx={sx}
        >
            <ReactQuill
                value={value}
                onChange={(value) => handleChange(value)}
                placeholder={label}
                disabled={disabled}
            />
        </div>
    );
}

Wysiwyg.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};