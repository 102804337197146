import Title from './Title';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DropDownMenu from './DropDownMenu';
import CheckOutsideRefWrapper from '../wrappers/CheckOutsideRefWrapper';
import "./Header.css";

export default function Header(props) {
    const { title } = props;

    const [ isOpenAccountMenu, setIsOpenAccountMenu ] = useState(false);

    return (
        <div className='header'>
            <Title title={title} />
            
            <CheckOutsideRefWrapper
                children={(
                    <>
                        <PersonOutlineOutlinedIcon onClick={() => setIsOpenAccountMenu(!isOpenAccountMenu)} sx={{
                            width: 24,
                            height: 24,
                            position: "absolute",
                            right: 30,
                            top: 16,
                            backgroundColor: "#abc",
                            padding: "7px",
                            color: "#fff",
                            borderRadius: 100,
                            cursor: "pointer"
                        }}/>
                        <DropDownMenu
                            items={[
                                { name: "Settings", href: "/users" },
                                { name: "Logout", href: "/logout" }
                            ]}
                            isOpen={isOpenAccountMenu}
                            sx={{
                                right: 30,
                                top: 60
                            }}
                        />
                    </>
                )}
                func={() => setIsOpenAccountMenu(false)}
            />
            <div style={{
                width: "calc(100% + 60px)",
                height: 1,
                backgroundColor: "#abc",
                marginTop: 20,
                marginLeft: "-30px",
            }}/>
        </div>
    )
}

Header.propTypes = {
    title: PropTypes.string
};