import DataTable from "../dataTable/DataTable";
import { edit as selected } from '../../slices/modelsSelectedSlice';
import { edit } from '../../slices/modelsFilterSlice';
import { appUrl } from "../../utils/fetch";
import Model from "./Model";
import ModelsFilter from "./ModelsFilter";
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Tabs from "../navigation/Tabs";
import Videos from "./Videos";
import Albums from "./AlbumsList";
import VideoList from "./VideoList";
import { useNavigate, useParams } from "react-router-dom";
import { PeopleAltOutlined, PersonOutlineOutlined } from "@mui/icons-material";



function ModelsList() {
    const { subTab, page } = useParams();
    const navigate = useNavigate();
    return (
        <div>
            <ModelsFilter />
            <DataTable 
                title="Models"
                dataUrl={`${appUrl}/api/models`}
                deleteUrl={`${appUrl}/api/model-delete`}
                editUrl={`/model`}
                filterStateName="modelsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/models`}
                url={"/models/list"}
                selectedStateName="modelsSelected"
                dispatchFunc={selected}
                editOpenFunc={() => navigate(`/models/list/page/${page ? page : 1}/detail/about`)}
                editComponent={(id, closeFunc) => {
                    return (
                        <div style={{width: "calc(1200px)"}}>
                        <Tabs
                            title={null}
                            tabs={
                                [
                                    { 
                                        title: "About",
                                        href: `/models/list/page/${page ? page : 1}/detail/about`,
                                        icon: <PersonOutlineOutlined/>,
                                        active: subTab === "about",
                                        content: (
                                            <Model id={id} closeFunc={() => closeFunc()}/>
                                        )
                                    },
                                    { 
                                        title: "Videos",
                                        href: `/models/list/page/${page ? page : 1}/detail/videos`,
                                        icon: <VideocamOutlinedIcon/>,
                                        active: subTab === "videos",
                                        content: (
                                            <VideoList disableFilter sx={{marginBottom: 60}} modelId={id}/>
                                        )
                                    },
                                    { 
                                        title: "Albums",
                                        href: `/models/list/page/${page ? page : 1}/detail/albums`,
                                        icon: <CameraAltOutlinedIcon/>,
                                        active: subTab === "albums",
                                        content: (
                                            <Albums disableFilter sx={{marginBottom: 60}} modelId={id}/>
                                        )
                                    }
                                ]
                            }
                        />
                        </div>
                    )
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    nameAvatar: {
                        label: "Avatar",
                        field: "image",
                        alt: "name"
                    },
                    stage_name: {
                        label: "Stage name",
                        field: "text",
                    },
                    name: {
                        label: "Name",
                        field: "text",
                    },
                    videos: {
                        label: "Videos",
                        field: "text",
                    },
                    albums: {
                        label: "Albums",
                        field: "text",
                    }
                }}
                largeView={
                    [
                        [
                            {
                                id: "name",
                                type: "avatar",
                                size: 60,
                                online: true
                            }
                        ],
                        [
                            {
                                id: "stage_name",
                                type: "boldText"
                            },
                            {
                                id: "name"
                            },
                            {
                                id: "registered",
                                type: "smallText"
                            }
                        ],
                        [
                            {
                                id: "videos",
                                type: "text",
                                padding: 40,
                                icon: <VideocamOutlinedIcon/>
                            },
                            {
                                id: "albums",
                                type: "text",
                                padding: 40,
                                icon: <CameraAltOutlinedIcon/>
                            },
                            {
                                id: "followersTotal",
                                type: "text",
                                padding: 40,
                                icon: <PersonAddAltOutlinedIcon/>
                            },
                        ],
                        [
                            {
                                id: "registeredStatus",
                                padding: 40,
                                type: "text"
                            },
                            {
                                id: "activityStatus",
                                padding: 40,
                                type: "text"
                            },
                            {
                                id: "type",
                                type: "text",
                                padding: 40,
                            },
                        ]
                    ]
                }
            />
        </div>
    );
}

export default ModelsList;