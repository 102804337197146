import React, { useEffect, useState } from "react";
import Tabs from "../navigation/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import AlbumsList from "./AlbumsList";
import Album from "./Album";
import { edit } from '../../slices/albumSlice';
import { useDispatch, useSelector } from "react-redux";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { appUrl } from "../../utils/fetch";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { edit as editImport } from '../../slices/importPhotoSlice';
import pornhubLogo from "../../assets/ph.png";
import xhamsterLogo from "../../assets/xhamster.png";
import xvideosLogo from "../../assets/xvideos.png";
import Form from "../form/Form";

function Albums() {
    const dispatch = useDispatch();
    const { tab } = useParams();
    const importData = useSelector((state) => state.importPhoto);
    const navigate = useNavigate();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Albums"}
                tabs={
                    [
                        { 
                            title: "All albums",
                            href: "/albums/list",
                            icon: <CameraAltOutlinedIcon />,
                            active: tab === "list",
                            content: (
                                <AlbumsList />
                            )
                        },
                        { 
                            title: "New album",
                            href: "/albums/new",
                            icon: <CameraAltOutlinedIcon />,
                            active: tab === "new",
                            content: (
                                <Album id={0}/>
                            )
                        },
                        { 
                            title: "Import",
                            icon: <FileDownloadOutlinedIcon />,
                            href: "/albums/import",
                            active: tab === "import",
                            content: (
                                <div style={{borderRadius: 20, padding: 20, backgroundColor: "#f2f4f8", marginTop: 20}}>
                                    <Form 
                                        inputs={{
                                            platform: {
                                                label: "Source",
                                                field: "buttonswitch",
                                                data: [
                                                    { id: "xhamster", label: "xhamster.com", image: xhamsterLogo },
                                                    { id: "xvideos", label: "xvideos.com", image: xvideosLogo },
                                                ],
                                                width: 12,
                                                border: true
                                            },
                                            url: {
                                                field: "textarea",
                                                label: "URL"
                                            }
                                        }}
                                        columns={2}
                                        stateName="importPhoto"
                                        dispatchFunc={editImport}
                                        api={`${appUrl}/api/album-import-${importData.platform}`}
                                        disabledDefault
                                        doneFunc={() => {navigate(`/albums/list/page/1`); dispatch(editImport("default"))}}
                                        detailId={0}
                                    />
                                </div>
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Albums;