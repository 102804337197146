import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    name: "",
    slug: "",
    description: "",
    parent_id: 0,
    status: 1,
    thumbnail: {},
    current_thumbnail: "",
    tags: [],
    seo_title: "",
    seo_keywords: "",
    seo_description: "",
    videos: 0,
    albums: 0
}

export const contentCategorySlice = createSlice({
    name: 'contentCategory',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = contentCategorySlice.actions

export default contentCategorySlice.reducer