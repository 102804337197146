import PropTypes from 'prop-types';
import './TubeChip.css';

function TubeButton(props) {
    const { label, sx, onClick } = props;
    return (
        <div style={sx} className={`tube-chip`} onClick={onClick}>
            {label}
        </div>
    );
}

export default TubeButton;

TubeButton.propTypes = {
    label: PropTypes.any,
    sx: PropTypes.object,
    onClick: PropTypes.func
};