import DataTable from "../dataTable/DataTable";
import { edit as grabbersList } from '../../slices/grabbersSelectedSlice';
import { appUrl } from "../../utils/fetch";
import ImportVideo from "./ImportVideo";
import "./GrabbersList.css";
import TubeSubmit from "../form/TubeSubmit";
import { useState } from "react";
import DialogComponent from "../dialog/Dialog";
import GrabbersSourceDialog from "./GrabbersSourceDialog";
import { edit as grabbersSource } from '../../slices/grabbersSourceSlice';
import { edit as createGrabber} from '../../slices/importSlice';
import { useDispatch, useSelector } from 'react-redux';

function GrabbersList() {
    const [isGrabberDialogOpen, setIsGrabberDialogOpen] = useState(false);
    const [ forceRefresh, setForceRefresh ] = useState(false);
    const dispatch = useDispatch();
    const grabber = useSelector((state) => state.import);

    const handleOpenSource = (row) => {
        dispatch(grabbersSource({id: row.id, isOpen: true, platform: row.platform, source: "" }));
    }

    return (
        <div>
            <DataTable 
                autoRefresh
                forceRefresh={forceRefresh}
                dataUrl={`${appUrl}/api/grabbers`}
                deleteUrl={`${appUrl}/api/grabber-delete`}
                editUrl={`/grabber`}
                url={"/grabbers"}
                actions={(
                    <TubeSubmit
                        label="Create new grabber"
                        onClick={() => {
                            setIsGrabberDialogOpen(true);
                            dispatch(createGrabber("default"));
                        }}
                    />
                )}
                editComponentIsLoading={grabber.isLoading}
                editComponent={(id, closeFunc = () => {}) => {
                    return (<ImportVideo id={id} closeFunc={closeFunc}/>)
                }}
                editMaxWidth={900}
                selectedStateName="grabbersSelected"
                dispatchFunc={grabbersList}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    platform: {
                        label: "Grabber",
                        field: "text"
                    },
                    description: {
                        label: "Description",
                        field: "text"
                    },
                    process: { 
                        label: "Process",
                        field: "html"
                    },
                    importType: {
                        label: "Method",
                        field: "text"
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    type: {
                        label: "Accessibility",
                        field: "text"
                    },
                    duplicated: {
                        label: "Duplicated",
                        field: "text"
                    },
                    data: {
                        label: "Imported data",
                        field: "text"
                    },
                    utils: {
                        label: "",
                        field: "html",
                        onClick: (row) => handleOpenSource(row)
                    },
                    extraRow: {
                        content: (id) => {
                            return (
                                <GrabbersSourceDialog id={id} onConfirm={() => setForceRefresh(prevState => !prevState)}/>
                            )
                        }
                    }
                }}
            />
            <DialogComponent
                content={(
                    <ImportVideo
                        closeFunc={() => setIsGrabberDialogOpen(false)}
                    />
                )}
                open={isGrabberDialogOpen}
                maxWidth={900}
                onClose={() => setIsGrabberDialogOpen(false)}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Delete",
                        color: "secondary",
                        disabled: true,
                        onClick: () => {dispatch(createGrabber("default"))},
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "secondary",
                        onClick: () => setIsGrabberDialogOpen(false),
                    }
                ]}
                form={true}
            />
        </div>
    );
}

export default GrabbersList;