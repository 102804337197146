import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import TubeButton from './TubeButton';
import './ButtonSwitch.css';

export default function ButtonSwitch(props) {
    const { label, stateName, valueName, dispatchFunc, data, multiple, sx } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();

    const handleChange = (id) => {
        if(multiple){
            let arr = [...value];
            const index = arr.indexOf(id);
            if (index === -1) {
                arr.push(id);
            } else {
                arr.splice(index, 1);
            }    
            dispatch(dispatchFunc({[valueName]: arr}));
            console.log(arr);
        }
        else {
            dispatch(dispatchFunc({[valueName]: id}));
        }
    }

    return (
        <div style={sx}>
            <div className="button-switch-label">{label}</div>
            {data.map((item, i) => (
                <TubeButton
                    key={i}
                    title={item.label}
                    image={item.image}
                    className={item.color ? `button-switch-color-${item.color}` : ""}
                    active={multiple ? value.includes(item.id) : value === item.id}
                    onClick={() => handleChange(item.id)}
                />
            ))}
        </div>
    );
}

ButtonSwitch.propTypes = {
    label: PropTypes.string,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    multiple: PropTypes.bool
};