import Form from "../form/Form";
import { edit } from '../../slices/importSlice';
import { appUrl, get } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { edit as editProgress } from "../../slices/progressSlice";
import { useEffect, useState } from "react";
import youtubeLogo from "../../assets/yt.png";
import pornhubLogo from "../../assets/ph.png";
import xvideosLogo from "../../assets/xvideos.png";
import xhamsterLogo from "../../assets/xhamster.png";
import axios from "axios";

function ImportVideo(props) {
    const { id, closeFunc } = props;
    const importData = useSelector((state) => state.import);
    const dispatch = useDispatch();
    const progress = useSelector((state) => state.progress);
    const [isRequesting, setIsRequesting] = useState(false);

    const getProgress = async () => {
        try {
            const response = await axios.get(`${appUrl}/api/import-progress`);
            dispatch(editProgress({
                message: response.data.message,
                progress: response.data.progress,
                inProgress: true
            }));
        } catch (error) {
            console.error('GET request error:', error);
        }
    };

    useEffect(() => {
        if (isRequesting) {
            const intervalId = setInterval(getProgress, 4000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isRequesting]);

    return (
        <Form 
            inputs={{
                platform: {
                    label: "Source",
                    field: "buttonswitch",
                    data: [
                        { id: "youtube", label: "youtube.com", image: youtubeLogo },
                        { id: "pornhub", label: "pornhub.com", image: pornhubLogo },
                        { id: "xvideos", label: "xvideos.com", image: xvideosLogo },
                        { id: "xhamster", label: "xhamster.com", image: xhamsterLogo },
                    ],
                    width: 12,
                    border: true
                },
                description: {
                    label: "Grabber description",
                    field: "text",
                    width: 12
                },
                importType: {
                    label: "Type",
                    field: "buttonswitch",
                    data: [
                        { id: 1, label: "Embed" },
                        { id: 2, label: "Download" },
                    ],
                    width: 3,
                    border: true
                },
                status: {
                    label: "Status",
                    field: "buttonswitch",
                    data: [
                        { id: 1, label: "Online", color: "green" },
                        { id: 2, label: "Offline", color: "red" },
                    ],
                    width: 2,
                    border: true
                },
                type: {
                    label: "Type",
                    field: "buttonswitch",
                    data: [
                        { id: 1, label: "Public" },
                        { id: 2, label: "Private" },
                        { id: 3, label: "Premium" },
                    ],
                    width: 4,
                    border: true
                },
                duplicated: {
                    label: "Duplicated videos",
                    field: "buttonswitch",
                    data: [
                        { id: 1, label: "Do not grab" },
                        { id: 2, label: "Grab all" },
                    ],
                    width: 3,
                    border: true
                },
                data: {
                    label: "Data",
                    field: "buttonswitch",
                    multiple: true,
                    data: [
                        { id: 1, label: "Title" },
                        { id: 2, label: "Description" },
                        { id: 3, label: "Rating" },
                        { id: 4, label: "Views" },
                        { id: 5, label: "Models" },
                        { id: 6, label: "Date added" },
                        { id: 7, label: "Channel" },
                        { id: 8, label: "Categories" },
                        { id: 9, label: "Tags" },
                    ],
                    border: true
                },
                title_limit: {
                    label: "Title limit",
                    field: "text",
                    width: 4
                },
                description_limit: {
                    label: "Description limit",
                    field: "text",
                    width: 4
                },
                empty: {
                    field: "empty",
                    height: 30
                }
            }}
            columns={2}
            stateName="import"
            dispatchFunc={edit}
            api={`${appUrl}/api/grabber`}
            detailId={id ? id : "new"}
            disabledDefault
            submitFunc={() => {
            }}
            doneFunc={() => {
                if(closeFunc){
                    closeFunc()
                }
            }}
        />
    );
}

export default ImportVideo;

ImportVideo.propTypes = {
    id: PropTypes.number,
    closeFunc: PropTypes.func
};