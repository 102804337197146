import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: "",
    models: [],
    tags: [],
    categories: []
}

export const channelsFilterSlice = createSlice({
    name: 'channelsFilter',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = channelsFilterSlice.actions

export default channelsFilterSlice.reducer