import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import NavigationLink from "./NavigationLink";
import Logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import RoofingRoundedIcon from '@mui/icons-material/RoofingRounded';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import { DescriptionOutlined } from '@mui/icons-material';

function Navigation() {

    const iconStyle = {
        fontSize: 24,
        marginRight: 1,
        color: "inherit"
    }
    return (
        <Paper elevation={0} sx={{
            backgroundColor: "#f2f4f8",
            height: "100vh",
            width: "185px",
            position: "fixed",
            left: 0,
            top: 0,
            right: "auto",
            bottom: "auto",
            zIndex: 100,
            paddingRight: "24px",
            paddingLeft: "24px"
        }}>
            <Link to="/">
                <img src={Logo} alt="TubesBooster" style={{
                    width: "100%",
                    padding: "24px 0"
                }}/>
            </Link>
            <MenuList 
                className="navigation"
                sx={{
                    width: "auto"
                }}
            >
                <NavigationLink icon={<RoofingRoundedIcon sx={iconStyle}/>} linkName="Home" src="/" />
                <NavigationLink icon={<VideocamOutlinedIcon sx={iconStyle}/>} linkName="Videos" src="/videos/list" />
                <NavigationLink icon={<FilterOutlinedIcon sx={iconStyle}/>} linkName="Albums" src="/albums/list" />
                <NavigationLink icon={<PeopleAltOutlinedIcon sx={iconStyle}/>} linkName="Users" src="/users/list" />
                <NavigationLink icon={<GroupsOutlinedIcon sx={iconStyle}/>} linkName="Channels" src="/channels/list" />
                <NavigationLink icon={<PeopleAltOutlinedIcon sx={iconStyle}/>} linkName="Models" src="/models/list" />
                <NavigationLink icon={<DescriptionOutlined sx={iconStyle}/>} linkName="Pages" src="/pages/list" />
                <NavigationLink icon={<TocOutlinedIcon sx={iconStyle}/>} linkName="Content Categories" src="/content-categories" />
            </MenuList>
        </Paper>
    );
}

export default Navigation;