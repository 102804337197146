import PropTypes from 'prop-types';
import './TubeButton.css';

function TubeButton(props) {
    const { title, sx, active, onClick, image, className } = props;
    return (
        <div style={sx} className={`tube-btn ${active ? "tube-btn-active" : ""} ${image ? "tube-btn-image" : ""} ${className ? className : ""}`} onClick={onClick}>
            {image ? (
                <img src={image} />
            ) : null}
            {title}
        </div>
    );
}

export default TubeButton;

TubeButton.propTypes = {
    title: PropTypes.any,
    sx: PropTypes.object,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    image: PropTypes.any,
    className: PropTypes.string
};