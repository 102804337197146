import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selected: [],
    selectedNames: []
};

export const usersSelectedSlice = createSlice({
    name: 'usersSelected',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = usersSelectedSlice.actions

export default usersSelectedSlice.reducer