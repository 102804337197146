import TextInput from "../form/TextInput";
import { Grid } from "@mui/material";
import { edit } from '../../slices/albumsFilterSlice';
import "./AlbumsFilter.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../form/MultiSelect";
import TubeSubmit from "../form/TubeSubmit";
import TubeSlider from "../form/TubeSlider";
import ButtonSwitch from "../form/ButtonSwitch";
import DatePicker from "../form/DatePicker";

function AlbumsFilter() {
    const metaData = useSelector((state) => state.meta);
    const [ isOpenFilter, setIsOpenFilter ] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="albums-filter" style={{
            borderRadius: "17px",
            padding: "17px 24px",
            backgroundColor: "#f2f4f8",
            marginTop: "20px"
        }}>
            <Grid container spacing={1}>
                <Grid item md={9}>
                    <TextInput
                        stateName="albumsFilter"
                        label="Search"
                        valueName="name"
                        dispatchFunc={edit}
                    />
                </Grid>
                <Grid item md={3}>
                    <TubeSubmit
                        label={"Reset"}   
                        className={"filter-button"}
                        sx={{
                            marginLeft: "5px"
                        }}
                        onClick={() => dispatch(edit("default"))} 
                    />
                    <TubeSubmit
                        label={"Filter and sort"}   
                        className={"filter-button"}
                        onClick={() => setIsOpenFilter(prevState => !prevState)} 
                    />
                </Grid>
                <Grid item md={12}>
                    <MultiSelect
                        stateName={"albumsFilter"}
                        valueName={"categories"}
                        dispatchFunc={edit}
                        label={"Categories"}
                        data={"content-categories-search"}
                    />
                </Grid>
                {isOpenFilter ? (
                    <>
                        <div class="line" />
                        <div style={{width: "100%", marginLeft: 7, marginTop: 15}}>
                            <MultiSelect
                                stateName={"albumsFilter"}
                                valueName={"users"}
                                dispatchFunc={edit}
                                label={"Users"}
                                data={"users-search"}
                            />
                        </div>
                        <div class="flex-row">
                            <div style={{
                                width: "200px",
                                paddingRight: "30px"
                            }}>
                                <div style={{fontWeight: 600}}>Total photos</div>
                                <TubeSlider
                                    label="Total photos"
                                    stateName={"albumsFilter"}
                                    valueName={"photos"}
                                    dispatchFunc={edit}
                                    range={metaData.maxPhotos ? metaData.maxPhotos : 999}
                                    unit={"photos"}
                                />
                            </div>
                            <div style={{paddingLeft: "0px"}}>
                                <div style={{fontWeight: 600}}>Status</div>
                                <ButtonSwitch
                                    stateName={"albumsFilter"}
                                    valueName={"status"}
                                    dispatchFunc={edit}
                                    data={[
                                        { id: 1, label: "Online", color: "green" },
                                        { id: 2, label: "Offline", color: "red" }
                                    ]}
                                />
                            </div>
                            <div style={{paddingLeft: "20px"}}>
                                <div style={{fontWeight: 600}}>Type</div>
                                <ButtonSwitch
                                    stateName={"albumsFilter"}
                                    valueName={"type"}
                                    dispatchFunc={edit}
                                    data={[
                                        { id: 1, label: "Public" },
                                        { id: 2, label: "Private" },
                                        { id: 3, label: "Premium" }
                                    ]}
                                />
                            </div>
                            <div style={{paddingLeft: "20px"}}>
                                <div style={{fontWeight: 600}}>Published</div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: 300
                                    }}
                                >
                                    <DatePicker
                                        stateName={"albumsFilter"}
                                        valueName={"date_from"}
                                        dispatchFunc={edit}
                                    />
                                    <div
                                        style={{
                                            width: 20
                                        }}
                                    />
                                    <DatePicker
                                        stateName={"albumsFilter"}
                                        valueName={"date_to"}
                                        dispatchFunc={edit}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </Grid>
        </div>
    );
}

export default AlbumsFilter;