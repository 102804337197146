import { Grid } from "@mui/material";
import { useSelector } from 'react-redux';

export default function UserStats(props) {
    const userData = useSelector((state) => state.user);
    return (
        <div className="video-card" style={{marginBottom: "20px"}}>
            <Grid container>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Videos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{userData.videos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Albums / Photos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{userData.albums} / {userData.photos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Followers</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>0</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Profile views</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>0</div>
                </Grid>
                <Grid item md={12}>
                    <div style={{width: "100%", height: 1, backgroundColor: "rgba(170, 187, 204, .5)", marginTop: 10, marginBottom: 10}}/>
                </Grid>
                <Grid item md={0}></Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Last seen</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>never</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Registered</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{userData.created_at}</div>
                </Grid>
            </Grid>
        </div>
    )
}