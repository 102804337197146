import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HexColorPicker } from "react-colorful";
import DialogComponent from '../dialog/Dialog';
import { Stack } from '@mui/system';

export default function ColorPickerComponent(props){
    const { label, stateName, valueName, dispatchFunc, disabled, type } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const [color, setColor] = useState(value);
    const [ openPicker, setOpenPicker ] = useState(false)
    const dispatch = useDispatch();

    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
    }

    const handleOpenPicker = () => {
        setOpenPicker(true);
    }

    const submitPicker = () => {
        handleChange(color);
        setOpenPicker(false);
    }
    
    return (
        <Stack direction={"row"} spacing={1}>
            <div
                style={{
                    width: 55,
                    height: 55,
                    backgroundColor: value,
                    borderRadius: 4
                }}
            />
            <TextField 
                id={valueName}
                label={label}
                variant="outlined"
                type={type ? type : "text"}
                value={value}
                disabled={disabled}
                onClick={handleOpenPicker}
                onChange={(e) => handleChange(e.target.value)}
                sx={{
                    width: "100%"
                }}
            />
            <DialogComponent
                open={openPicker}
                onClose={() => {setOpenPicker(false)}}
                title="Color Picker"
                content={(
                    <HexColorPicker color={color} onChange={setColor} />
                )}
                buttons={[
                    {
                        variant: "contained",
                        label: "Apply",
                        color: "primary",
                        onClick: () => submitPicker()
                    },
                    {
                        variant: "text",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => setOpenPicker(false),
                    }
                ]}
            />
        </Stack>
    );
}

ColorPickerComponent.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string
};