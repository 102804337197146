import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    name: "",
    color: ""
}

export const contentTagSlice = createSlice({
    name: 'contentTag',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = contentTagSlice.actions

export default contentTagSlice.reducer