import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    source: "",
    isOpen: false,
    platform: "youtube"
};

export const grabbersSourceSlice = createSlice({
    name: 'grabbersSource',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = grabbersSourceSlice.actions

export default grabbersSourceSlice.reducer