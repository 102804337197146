import TextInput from "../form/TextInput";
import { Grid } from "@mui/material";
import { edit } from '../../slices/channelsFilterSlice';
import "./ChannelsFilter.css";
import MultiSelect from "../form/MultiSelect";

function ChannelsFilter() {
    return (
        <div className="channels-filter" style={{
            borderRadius: "17px",
            padding: "17px 24px",
            backgroundColor: "#f2f4f8",
            marginTop: "20px"
        }}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <TextInput
                        stateName="channelsFilter"
                        label="Search"
                        valueName="name"
                        dispatchFunc={edit}
                    />
                </Grid>
                <Grid item md={4}>
                    <MultiSelect
                        stateName="channelsFilter"
                        label="Categories"
                        valueName="categories"
                        dispatchFunc={edit}
                        data={"content-categories-search"}
                    />
                </Grid>
                <Grid item md={4}>
                    <MultiSelect
                        stateName="channelsFilter"
                        label="Models"
                        valueName="models"
                        dispatchFunc={edit}
                        data={"models-search"}
                    />
                </Grid>
                <Grid item md={4}>
                    <MultiSelect
                        stateName="channelsFilter"
                        label="Tags"
                        valueName="tags"
                        dispatchFunc={edit}
                        data={"content-tags-search"}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default ChannelsFilter;