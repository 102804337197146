import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import Tabs from "../navigation/Tabs";
import User from "./User";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import VideoList from "./VideoList";
import CameraAltOutlined from "@mui/icons-material/CameraAltOutlined";
import Albums from "./AlbumsList";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function UserTabs(props){
    
    const { subTab, page, subpage } = useParams();
    const { id, closeFunc, noRedirect, mainPage } = props;
    const [ noRedirectSubtab, setNoRedirectSubtab ] = useState("about");
    
    return (
        <Tabs
            title={null}
            tabs={
                [
                    { 
                        title: "About",
                        href: noRedirect ? "" : `/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/about`,
                        icon: <PersonOutlineOutlined/>,
                        active: (!noRedirect && subTab === "about") || (noRedirect && noRedirectSubtab === "about") || !subTab,
                        onClick: noRedirect ? () => setNoRedirectSubtab("about") : null,
                        content: (
                            <User id={id} closeFunc={() => closeFunc()}/>
                        )
                    },
                    { 
                        title: "Videos",
                        href: noRedirect ? "" : `/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/videos/page/1`,
                        icon: <VideocamOutlined/>,
                        active: (!noRedirect && subTab === "videos") || (noRedirect && noRedirectSubtab === "videos"),
                        onClick: noRedirect ? () => setNoRedirectSubtab("videos") : null,
                        content: (
                            <VideoList disableFilter sx={{marginBottom: 60}} userId={id} subpageUrl={`/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/videos`} />
                        )
                    },
                    { 
                        title: "Albums",
                        href: noRedirect ? "" : `/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/albums/page/1`,
                        icon: <CameraAltOutlined/>,
                        active: (!noRedirect && subTab === "albums") || (noRedirect && noRedirectSubtab === "albums"),
                        onClick: noRedirect ? () => setNoRedirectSubtab("albums") : null,
                        content: (
                            <Albums disableFilter sx={{marginBottom: 60}} userId={id} subpageUrl={`/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/albums`} />
                        )
                    },
                    { 
                        title: "Friends",
                        href: noRedirect ? "" : `/${mainPage ? mainPage : "users"}/list/page/${page ? page : 1}/detail/friends`,
                        icon: <PeopleAltOutlined/>,
                        active: (!noRedirect && subTab === "friends") || (noRedirect && noRedirectSubtab === "friends"),
                        onClick: noRedirect ? () => setNoRedirectSubtab("friends") : null,
                        content: (
                            <></>
                        )
                    }
                ]
            }
        />
    );
}