import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextInput from "./TextInput";
import TextArea from "./TextArea";
import Upload from "./Upload";
import Switch from "./Switch";
import SelectComponent from "./Select";
import PropTypes from 'prop-types';
import CheckboxComponent from "./Checkbox";
import DatePicker from "./DatePicker";
import { useSelector, useDispatch } from "react-redux";
import { post, get } from "../../utils/fetch";
import ColorPickerComponent from "./ColorPicker";
import MultiSelect from "./MultiSelect";
import Gallery from "./Gallery";
import TubeSubmit from "./TubeSubmit";
import './Form.css';
import ButtonSwitch from "./ButtonSwitch";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import TubeCover from "./TubeCover";
import DialogComponent from "../dialog/Dialog";
import Wysiwyg from "./Wysiwyg";

const getData = (data, setIsLoading, dispatch, dispatchFunc) => {
    dispatch(dispatchFunc(data.data));
    setIsLoading(false);
}

export default function Form(props){
    const { inputs, stateName, dispatchFunc, api, detailId, disabledDefault, meta, submitFunc, doneFunc, isRequesting, required, validation } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState([]);
    const [ serverMessage, setServerMessage ] = useState("");
    const form = useSelector((state) => state[stateName]);
    const dispatch = useDispatch();

    useEffect(() => {
        if((detailId !== 0 || meta) && api){
            if(!disabledDefault && detailId === "new"){
                dispatch(dispatchFunc("default"));
            }
            setIsLoading(true);
            get(`${api}/${detailId}`, (data) => {
                getData(data, setIsLoading, dispatch, dispatchFunc);
                if(form.isLoading){
                    dispatch(dispatchFunc({isLoading: 1}));
                }
            }, () => {
                if(form.isLoading){
                    dispatch(dispatchFunc({isLoading: 2}));
                }
            }
            );
        }
        else {
            if(!disabledDefault){
                dispatch(dispatchFunc("default"));
            }
        }
    }, [detailId, api, dispatch, dispatchFunc, disabledDefault, meta]);

    const handleSubmit = () => {
        setIsLoading(true)
        post(api, form, 
            (result) => {
                setIsLoading(false);
                if(result.internalError && result.internalError.length > 0){
                    setError(result.internalError);
                }
                else {
                    setError([]);
                    if(result.data && result.data.error_message){
                        setServerMessage(result.data.error_message);
                    }
                    else {
                        if(doneFunc){
                            doneFunc();
                        } 
                    }
                }
            },  
            () => {
                if(submitFunc){
                    submitFunc();
                }
            },
            required,
            validation
        )
    }

    useEffect(() => {
    }, [form.isLoading])

    const renderField = (input, key, multi) => {
        const { label, field, data, currentImage, deleteUrl, border, multiple, height, prepend, sx, buttonLabel, noInput, logo, cover, thumbnail, time, extraFuncType, extraFuncName, extraFuncLabel, component, disableMultiple, loadEmpty, slugify, errorMsg, max, lowercase } = input;

        let metaData = {};
        if((field === "multiselect" && typeof data === "string" && form.meta) || (field === "select" && typeof data === "string" && form.meta)){
            if(prepend){
                metaData = prepend;    
            }
            if(form.meta[key]){
                form.meta[key].forEach(entry => {
                    metaData = { ...metaData, [entry.id]: entry.name };
                });
            }
        }
        else {
            metaData = data;
        }

        switch(field) {
            case "textarea":
                return (
                    <TextArea 
                        label={label}
                        valueName={key}
                        className={error.includes(key) ? "internal-error" : ""}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        sx={multi ? {marginTop: "10px"} : null}
                    />    
                );
            case "wysiwyg":
                return (
                    <Wysiwyg 
                        label={label}
                        valueName={key}
                        className={error.includes(key) ? "internal-error" : ""}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        sx={multi ? {marginTop: "10px"} : null}
                    />    
                );
            case "cover":
                return (
                    <TubeCover 
                        stateName={stateName}
                        logo={logo}
                        cover={cover}
                    />    
                );
            case "upload":
                return (
                    <Upload 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        currentImage={currentImage}
                        border={border}
                        sx={sx}
                        buttonLabel={buttonLabel}
                        noInput={noInput}
                        thumbnail={thumbnail}
                        className={error.includes(key) ? "internal-error" : ""}
                    />    
                );
            case "select":
                return (
                    <SelectComponent 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        data={metaData}
                        disabled={isLoading}
                        sx={multi ? {marginTop: "10px", ...sx} : sx}
                    />    
                );
            case "buttonswitch":
                return (
                    <ButtonSwitch 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        data={data}
                        disabled={isLoading}
                        multiple={multiple}
                        sx={sx}
                    />    
                );
            case "switch":
                return (
                    <Switch 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                    />    
                );
            case "checkbox":
                return (
                    <CheckboxComponent 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                    />    
                );
            case "date":
                return (
                    <DatePicker 
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        time={time}
                        sx={multi ? {marginTop: "10px", ...sx} : sx}
                    />    
                );
            case "password":
                return (
                    <TextInput
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        type={"password"}
                        sx={multi ? {marginTop: "10px", ...sx} : sx}
                        className={error.includes(key) ? "internal-error" : ""}
                        errorMsg={errorMsg}
                        max={max}
                    />    
                );
            case "color":
                return (
                    <ColorPickerComponent
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                    />    
                );
            case "multiselect":
                return (
                    <MultiSelect
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        data={data}
                        disabled={isLoading}
                        sx={multi ? {marginTop: "10px", ...sx} : sx}
                        extraFuncName={extraFuncName}
                        extraFuncType={extraFuncType}
                        extraFuncLabel={extraFuncLabel}
                        disableMultiple={disableMultiple}
                        loadEmpty={loadEmpty}
                        errorMsg={errorMsg}
                        className={error.includes(key) ? "internal-error" : ""}
                        lowercase={lowercase}
                    />    
                );
            case "gallery":
                return (
                    <Gallery
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        deleteUrl={deleteUrl}
                        sx={sx}
                        thumbnail={thumbnail}
                        errorMsg={errorMsg}
                        className={error.includes(key) ? "internal-error" : ""}
                    />    
                );
            case "empty":
                return (
                    <div style={{
                        width: 1,
                        height: height ? height : "calc(((50vw - 60px)/16*9) + ((50vw - 92px) / 16 * 9 / 4) - 250px)"
                    }}/>    
                );
            case "html":
                return (
                    <>{component}</>    
                );
            default:
                return (
                    <TextInput
                        label={label}
                        valueName={key}
                        stateName={stateName}
                        dispatchFunc={dispatchFunc}
                        disabled={isLoading}
                        className={error.includes(key) ? "internal-error" : ""}
                        sx={multi ? {marginTop: "10px", ...sx} : sx}
                        slugify={slugify}
                        errorMsg={errorMsg}
                        max={max}
                    />    
                );
        }
    }

    return (
        <Grid container>
            {isRequesting ? (
                <Box sx={{ width: '100%', paddingTop: "30px" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid 
                        container 
                        spacing={1}
                        sx={{
                            marginTop: "4px"
                        }}
                        direction="row"
                        alignItems="flex-start"
                    >
                        {Object.keys(inputs).map((key, i) => {
                            return (
                                <Grid 
                                    item 
                                    md={inputs[key]["width"] ? inputs[key]["width"] : 12}
                                    key={i}
                                    className={inputs[key]["border"] ? `input-border` : ""}
                                >
                                    {typeof inputs[key] === "string" ? (
                                        <TextInput 
                                            label={inputs[key]}
                                            key={i}
                                            valueName={key}
                                            stateName={stateName}
                                            dispatchFunc={dispatchFunc}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            {renderField(inputs[key], key)}
                                            {inputs[key]["merged"] ? Object.keys(inputs[key]["merged"]).map(multiRowKey => (
                                                <>{renderField(inputs[key]["merged"][multiRowKey], multiRowKey, true)}</>
                                            )) : null}
                                        </React.Fragment>
                                    ) }
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid 
                        container
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <TubeSubmit 
                                isLoading={isLoading}
                                onClick={handleSubmit}
                                className="form-submit"
                                label="Save"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <DialogComponent
                content={serverMessage}
                open={serverMessage !== ""}
                onClose={() => setServerMessage("")}
                buttons={[
                    {
                        variant: "contained",
                        label: "OK",
                        color: "primary",
                        onClick: () => setServerMessage(""),
                    }
                ]}
            />
        </Grid>
    )
}

Form.propTypes = {
    inputs: PropTypes.object.isRequired,
    stateName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    api: PropTypes.string,
    detailId: PropTypes.string,
    disabledDefault: PropTypes.bool,
    meta: PropTypes.bool,
    submitFunc: PropTypes.func,
    doneFunc: PropTypes.func,
    isRequesting: PropTypes.bool
};